::selection {
    color: none;
    background: none;
}


/* For Mozilla Firefox */

::-moz-selection {
    color: none;
    background: none;
}

.app {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100vh;
}

.app > img{
    max-width: 9rem;

}


/* bg-circle animation */

.animations {
    position: absolute !important;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    z-index: 1;
    display: none;
}

.animated-div1 {
    z-index: 1;
    position: fixed;
    width: 30vw;
    height: 50vh;
    left: 0%;
    background: radial-gradient(123% 89% at 80% 50%, rgba(3, 59, 124, 0.701) 0%, rgba(22, 27, 35, 0.06) 57.81%, rgba(22, 27, 35, 0) 100%)/* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */
    ;
    filter: blur(155px);
    animation: bgChange 20s linear infinite;
}

.animated-div2 {
    z-index: 1;
    position: fixed;
    width: 10vw;
    height: 13vh;
    left: 70%;
    margin-top: 5%;
    background: radial-gradient(123% 89% at 80% 50%, rgba(3, 102, 124, 0.701) 0%, rgba(22, 27, 35, 0.06) 57.81%, rgba(22, 27, 35, 0) 100%)/* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */
    ;
    filter: blur(50px);
    animation: bgChanges 20s linear infinite;
}

@keyframes bgChange {
    0% {
        left: 0%;
    }
    35% {
        left: 50%;
        margin-top: 20%;
    }
    50% {
        left: 20%;
        margin-top: 30%;
    }
    65% {
        left: 35%;
        margin-top: 27%;
    }
    100% {
        left: 0%;
    }
}

@keyframes bgChanges {
    0% {
        left: 70%;
        margin-top: 5%;
    }
    35% {
        left: 80%;
        margin-top: 10%;
    }
    50% {
        left: 70%;
        margin-top: 23%;
    }
    75% {
        left: 30%;
        margin-top: 8%;
    }
    100% {
        left: 70%;
        margin-top: 5%;
    }
}