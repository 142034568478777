    * {
        font-size: 10px;
        cursor: none !important;
    }
    
    html {
        scroll-behavior: smooth;
        height: -webkit-fill-available;
    }
    
    body {
        margin: 0;
        font-family: 'League Spartan', sans-serif;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        font-size: 10px;
        max-width: 100vw !important;
        overflow-x: hidden !important;
        -ms-overflow-style: none;
        /* scrollbar-width: none; */
        -webkit-touch-callout: none;
        -webkit-user-select: none;
        -khtml-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
        display: flex;
        flex-direction: column;
        height: 100vh;
        overflow: hidden;
        overflow-y: scroll;
    }
    
     ::-moz-selection {
        background: #00B5C0;
    }
    
     ::selection {
        color: rgb(13, 13, 28);
        background: #00B5C0 !important;
    }
    
     ::placeholder {
        color: rgba(204, 214, 246, 0.5);
        opacity: 1;
    }
    
     :-ms-input-placeholder {
        color: rgba(204, 214, 246, 0.5);
    }
    
     ::-ms-input-placeholder {
        color: rgba(204, 214, 246, 0.5);
    }
    
    code {
        font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
    }
    
    body::-webkit-scrollbar {
        display: none;
    }
    
     ::-webkit-scrollbar {
        width: 0px;
        background: transparent;
    }
    
    body::-webkit-scrollbar {
        width: 0.2em;
        background-color: #F5F5F5;
    }
    
    body::-webkit-scrollbar-thumb {
        background-color: #000000;
    }
    
    .scrollbar-track-y {
        display: none !important;
    }
    
    .scrollbar-track-x {
        display: none !important;
    }