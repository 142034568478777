    * {
        font-size: 10px;
        cursor: none !important;
    }
    
    html {
        scroll-behavior: smooth;
        height: -webkit-fill-available;
    }
    
    body {
        margin: 0;
        font-family: 'League Spartan', sans-serif;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        font-size: 10px;
        max-width: 100vw !important;
        overflow-x: hidden !important;
        -ms-overflow-style: none;
        /* scrollbar-width: none; */
        -webkit-touch-callout: none;
        -webkit-user-select: none;
        user-select: none;
        display: flex;
        flex-direction: column;
        height: 100vh;
        overflow: hidden;
        overflow-y: scroll;
    }
    
     ::selection {
        color: rgb(13, 13, 28);
        background: #00B5C0 !important;
    }
    
     ::-webkit-input-placeholder {
        color: rgba(204, 214, 246, 0.5);
        opacity: 1;
    }
    
     ::placeholder {
        color: rgba(204, 214, 246, 0.5);
        opacity: 1;
    }
    
     :-ms-input-placeholder {
        color: rgba(204, 214, 246, 0.5);
    }
    
     ::-ms-input-placeholder {
        color: rgba(204, 214, 246, 0.5);
    }
    
    code {
        font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
    }
    
    body::-webkit-scrollbar {
        display: none;
    }
    
     ::-webkit-scrollbar {
        width: 0px;
        background: transparent;
    }
    
    body::-webkit-scrollbar {
        width: 0.2em;
        background-color: #F5F5F5;
    }
    
    body::-webkit-scrollbar-thumb {
        background-color: #000000;
    }
    
    .scrollbar-track-y {
        display: none !important;
    }
    
    .scrollbar-track-x {
        display: none !important;
    }
::selection {
    color: none;
    background: none;
}


/* For Mozilla Firefox */

::-moz-selection {
    color: none;
    background: none;
}

.app {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100vh;
}

.app > img{
    max-width: 9rem;

}


/* bg-circle animation */

.animations {
    position: absolute !important;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    z-index: 1;
    display: none;
}

.animated-div1 {
    z-index: 1;
    position: fixed;
    width: 30vw;
    height: 50vh;
    left: 0%;
    background: radial-gradient(123% 89% at 80% 50%, rgba(3, 59, 124, 0.701) 0%, rgba(22, 27, 35, 0.06) 57.81%, rgba(22, 27, 35, 0) 100%)/* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */
    ;
    -webkit-filter: blur(155px);
            filter: blur(155px);
    -webkit-animation: bgChange 20s linear infinite;
            animation: bgChange 20s linear infinite;
}

.animated-div2 {
    z-index: 1;
    position: fixed;
    width: 10vw;
    height: 13vh;
    left: 70%;
    margin-top: 5%;
    background: radial-gradient(123% 89% at 80% 50%, rgba(3, 102, 124, 0.701) 0%, rgba(22, 27, 35, 0.06) 57.81%, rgba(22, 27, 35, 0) 100%)/* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */
    ;
    -webkit-filter: blur(50px);
            filter: blur(50px);
    -webkit-animation: bgChanges 20s linear infinite;
            animation: bgChanges 20s linear infinite;
}

@-webkit-keyframes bgChange {
    0% {
        left: 0%;
    }
    35% {
        left: 50%;
        margin-top: 20%;
    }
    50% {
        left: 20%;
        margin-top: 30%;
    }
    65% {
        left: 35%;
        margin-top: 27%;
    }
    100% {
        left: 0%;
    }
}

@keyframes bgChange {
    0% {
        left: 0%;
    }
    35% {
        left: 50%;
        margin-top: 20%;
    }
    50% {
        left: 20%;
        margin-top: 30%;
    }
    65% {
        left: 35%;
        margin-top: 27%;
    }
    100% {
        left: 0%;
    }
}

@-webkit-keyframes bgChanges {
    0% {
        left: 70%;
        margin-top: 5%;
    }
    35% {
        left: 80%;
        margin-top: 10%;
    }
    50% {
        left: 70%;
        margin-top: 23%;
    }
    75% {
        left: 30%;
        margin-top: 8%;
    }
    100% {
        left: 70%;
        margin-top: 5%;
    }
}

@keyframes bgChanges {
    0% {
        left: 70%;
        margin-top: 5%;
    }
    35% {
        left: 80%;
        margin-top: 10%;
    }
    50% {
        left: 70%;
        margin-top: 23%;
    }
    75% {
        left: 30%;
        margin-top: 8%;
    }
    100% {
        left: 70%;
        margin-top: 5%;
    }
}
html, body {
    height: 100%; }
  
  body {
    background: black;
    background-size: cover; }
  h1{
    color: white;
  }
  .bg{
    position: absolute;
  }
  .firefly {
    position: fixed;
    left: 50%;
    top: 50%;
    width: 0.4vw;
    height: 0.4vw;
    margin: -0.2vw 0 0 9.8vw;
    -webkit-animation: ease 200s alternate infinite;
            animation: ease 200s alternate infinite;
    pointer-events: none; }
    .firefly::before, .firefly::after {
      content: '';
      position: absolute;
      width: 100%;
      height: 100%;
      border-radius: 50%;
      -webkit-transform-origin: -10vw;
              transform-origin: -10vw; }
    .firefly::before {
      background: black;
      opacity: 0.4;
      -webkit-animation: drift ease alternate infinite;
              animation: drift ease alternate infinite; }
    .firefly::after {
      background: white;
      opacity: 0;
      box-shadow: 0 0 0vw 0vw yellow;
      -webkit-animation: drift ease alternate infinite, flash ease infinite;
              animation: drift ease alternate infinite, flash ease infinite; }
  
  .firefly:nth-child(1) {
    -webkit-animation-name: move1;
            animation-name: move1; }
    .firefly:nth-child(1)::before {
      -webkit-animation-duration: 17s;
              animation-duration: 17s; }
    .firefly:nth-child(1)::after {
      -webkit-animation-duration: 17s, 8165ms;
              animation-duration: 17s, 8165ms;
      -webkit-animation-delay: 0ms, 3081ms;
              animation-delay: 0ms, 3081ms; }
  
  @-webkit-keyframes move1 {
    0% {
      -webkit-transform: translateX(22vw) translateY(44vh) scale(0.9);
              transform: translateX(22vw) translateY(44vh) scale(0.9); }
    5% {
      -webkit-transform: translateX(-46vw) translateY(13vh) scale(0.66);
              transform: translateX(-46vw) translateY(13vh) scale(0.66); }
    10% {
      -webkit-transform: translateX(27vw) translateY(21vh) scale(0.4);
              transform: translateX(27vw) translateY(21vh) scale(0.4); }
    15% {
      -webkit-transform: translateX(37vw) translateY(48vh) scale(0.68);
              transform: translateX(37vw) translateY(48vh) scale(0.68); }
    20% {
      -webkit-transform: translateX(50vw) translateY(20vh) scale(0.73);
              transform: translateX(50vw) translateY(20vh) scale(0.73); }
    25% {
      -webkit-transform: translateX(-16vw) translateY(-30vh) scale(0.51);
              transform: translateX(-16vw) translateY(-30vh) scale(0.51); }
    30% {
      -webkit-transform: translateX(-23vw) translateY(-39vh) scale(0.37);
              transform: translateX(-23vw) translateY(-39vh) scale(0.37); }
    35% {
      -webkit-transform: translateX(-32vw) translateY(-40vh) scale(1);
              transform: translateX(-32vw) translateY(-40vh) scale(1); }
    40% {
      -webkit-transform: translateX(44vw) translateY(-6vh) scale(0.54);
              transform: translateX(44vw) translateY(-6vh) scale(0.54); }
    45% {
      -webkit-transform: translateX(35vw) translateY(-22vh) scale(0.67);
              transform: translateX(35vw) translateY(-22vh) scale(0.67); }
    50% {
      -webkit-transform: translateX(-34vw) translateY(27vh) scale(0.84);
              transform: translateX(-34vw) translateY(27vh) scale(0.84); }
    55% {
      -webkit-transform: translateX(32vw) translateY(-13vh) scale(0.85);
              transform: translateX(32vw) translateY(-13vh) scale(0.85); }
    60% {
      -webkit-transform: translateX(-18vw) translateY(-37vh) scale(0.8);
              transform: translateX(-18vw) translateY(-37vh) scale(0.8); }
    65% {
      -webkit-transform: translateX(33vw) translateY(-41vh) scale(0.74);
              transform: translateX(33vw) translateY(-41vh) scale(0.74); }
    70% {
      -webkit-transform: translateX(-30vw) translateY(48vh) scale(0.71);
              transform: translateX(-30vw) translateY(48vh) scale(0.71); }
    75% {
      -webkit-transform: translateX(-20vw) translateY(-21vh) scale(0.27);
              transform: translateX(-20vw) translateY(-21vh) scale(0.27); }
    80% {
      -webkit-transform: translateX(14vw) translateY(29vh) scale(0.89);
              transform: translateX(14vw) translateY(29vh) scale(0.89); }
    85% {
      -webkit-transform: translateX(-19vw) translateY(-16vh) scale(0.69);
              transform: translateX(-19vw) translateY(-16vh) scale(0.69); }
    90% {
      -webkit-transform: translateX(25vw) translateY(12vh) scale(0.29);
              transform: translateX(25vw) translateY(12vh) scale(0.29); }
    95% {
      -webkit-transform: translateX(7vw) translateY(33vh) scale(0.74);
              transform: translateX(7vw) translateY(33vh) scale(0.74); }
    100% {
      -webkit-transform: translateX(8vw) translateY(-35vh) scale(0.32);
              transform: translateX(8vw) translateY(-35vh) scale(0.32); } }
  
  @keyframes move1 {
    0% {
      -webkit-transform: translateX(22vw) translateY(44vh) scale(0.9);
              transform: translateX(22vw) translateY(44vh) scale(0.9); }
    5% {
      -webkit-transform: translateX(-46vw) translateY(13vh) scale(0.66);
              transform: translateX(-46vw) translateY(13vh) scale(0.66); }
    10% {
      -webkit-transform: translateX(27vw) translateY(21vh) scale(0.4);
              transform: translateX(27vw) translateY(21vh) scale(0.4); }
    15% {
      -webkit-transform: translateX(37vw) translateY(48vh) scale(0.68);
              transform: translateX(37vw) translateY(48vh) scale(0.68); }
    20% {
      -webkit-transform: translateX(50vw) translateY(20vh) scale(0.73);
              transform: translateX(50vw) translateY(20vh) scale(0.73); }
    25% {
      -webkit-transform: translateX(-16vw) translateY(-30vh) scale(0.51);
              transform: translateX(-16vw) translateY(-30vh) scale(0.51); }
    30% {
      -webkit-transform: translateX(-23vw) translateY(-39vh) scale(0.37);
              transform: translateX(-23vw) translateY(-39vh) scale(0.37); }
    35% {
      -webkit-transform: translateX(-32vw) translateY(-40vh) scale(1);
              transform: translateX(-32vw) translateY(-40vh) scale(1); }
    40% {
      -webkit-transform: translateX(44vw) translateY(-6vh) scale(0.54);
              transform: translateX(44vw) translateY(-6vh) scale(0.54); }
    45% {
      -webkit-transform: translateX(35vw) translateY(-22vh) scale(0.67);
              transform: translateX(35vw) translateY(-22vh) scale(0.67); }
    50% {
      -webkit-transform: translateX(-34vw) translateY(27vh) scale(0.84);
              transform: translateX(-34vw) translateY(27vh) scale(0.84); }
    55% {
      -webkit-transform: translateX(32vw) translateY(-13vh) scale(0.85);
              transform: translateX(32vw) translateY(-13vh) scale(0.85); }
    60% {
      -webkit-transform: translateX(-18vw) translateY(-37vh) scale(0.8);
              transform: translateX(-18vw) translateY(-37vh) scale(0.8); }
    65% {
      -webkit-transform: translateX(33vw) translateY(-41vh) scale(0.74);
              transform: translateX(33vw) translateY(-41vh) scale(0.74); }
    70% {
      -webkit-transform: translateX(-30vw) translateY(48vh) scale(0.71);
              transform: translateX(-30vw) translateY(48vh) scale(0.71); }
    75% {
      -webkit-transform: translateX(-20vw) translateY(-21vh) scale(0.27);
              transform: translateX(-20vw) translateY(-21vh) scale(0.27); }
    80% {
      -webkit-transform: translateX(14vw) translateY(29vh) scale(0.89);
              transform: translateX(14vw) translateY(29vh) scale(0.89); }
    85% {
      -webkit-transform: translateX(-19vw) translateY(-16vh) scale(0.69);
              transform: translateX(-19vw) translateY(-16vh) scale(0.69); }
    90% {
      -webkit-transform: translateX(25vw) translateY(12vh) scale(0.29);
              transform: translateX(25vw) translateY(12vh) scale(0.29); }
    95% {
      -webkit-transform: translateX(7vw) translateY(33vh) scale(0.74);
              transform: translateX(7vw) translateY(33vh) scale(0.74); }
    100% {
      -webkit-transform: translateX(8vw) translateY(-35vh) scale(0.32);
              transform: translateX(8vw) translateY(-35vh) scale(0.32); } }
  
  .firefly:nth-child(2) {
    -webkit-animation-name: move2;
            animation-name: move2; }
    .firefly:nth-child(2)::before {
      -webkit-animation-duration: 16s;
              animation-duration: 16s; }
    .firefly:nth-child(2)::after {
      -webkit-animation-duration: 16s, 9242ms;
              animation-duration: 16s, 9242ms;
      -webkit-animation-delay: 0ms, 3948ms;
              animation-delay: 0ms, 3948ms; }
  
  @-webkit-keyframes move2 {
    0% {
      -webkit-transform: translateX(2vw) translateY(7vh) scale(0.83);
              transform: translateX(2vw) translateY(7vh) scale(0.83); }
    4% {
      -webkit-transform: translateX(-3vw) translateY(7vh) scale(0.9);
              transform: translateX(-3vw) translateY(7vh) scale(0.9); }
    8% {
      -webkit-transform: translateX(25vw) translateY(50vh) scale(0.68);
              transform: translateX(25vw) translateY(50vh) scale(0.68); }
    12% {
      -webkit-transform: translateX(35vw) translateY(36vh) scale(0.7);
              transform: translateX(35vw) translateY(36vh) scale(0.7); }
    16% {
      -webkit-transform: translateX(-11vw) translateY(28vh) scale(0.88);
              transform: translateX(-11vw) translateY(28vh) scale(0.88); }
    20% {
      -webkit-transform: translateX(9vw) translateY(5vh) scale(0.58);
              transform: translateX(9vw) translateY(5vh) scale(0.58); }
    24% {
      -webkit-transform: translateX(44vw) translateY(16vh) scale(0.91);
              transform: translateX(44vw) translateY(16vh) scale(0.91); }
    28% {
      -webkit-transform: translateX(2vw) translateY(21vh) scale(0.9);
              transform: translateX(2vw) translateY(21vh) scale(0.9); }
    32% {
      -webkit-transform: translateX(36vw) translateY(-3vh) scale(0.94);
              transform: translateX(36vw) translateY(-3vh) scale(0.94); }
    36% {
      -webkit-transform: translateX(-33vw) translateY(13vh) scale(0.59);
              transform: translateX(-33vw) translateY(13vh) scale(0.59); }
    40% {
      -webkit-transform: translateX(-29vw) translateY(6vh) scale(0.6);
              transform: translateX(-29vw) translateY(6vh) scale(0.6); }
    44% {
      -webkit-transform: translateX(-29vw) translateY(32vh) scale(0.39);
              transform: translateX(-29vw) translateY(32vh) scale(0.39); }
    48% {
      -webkit-transform: translateX(39vw) translateY(-10vh) scale(0.48);
              transform: translateX(39vw) translateY(-10vh) scale(0.48); }
    52% {
      -webkit-transform: translateX(-11vw) translateY(-16vh) scale(0.7);
              transform: translateX(-11vw) translateY(-16vh) scale(0.7); }
    56% {
      -webkit-transform: translateX(48vw) translateY(-39vh) scale(0.46);
              transform: translateX(48vw) translateY(-39vh) scale(0.46); }
    60% {
      -webkit-transform: translateX(30vw) translateY(25vh) scale(0.44);
              transform: translateX(30vw) translateY(25vh) scale(0.44); }
    64% {
      -webkit-transform: translateX(-43vw) translateY(-26vh) scale(0.82);
              transform: translateX(-43vw) translateY(-26vh) scale(0.82); }
    68% {
      -webkit-transform: translateX(11vw) translateY(-1vh) scale(1);
              transform: translateX(11vw) translateY(-1vh) scale(1); }
    72% {
      -webkit-transform: translateX(13vw) translateY(31vh) scale(0.65);
              transform: translateX(13vw) translateY(31vh) scale(0.65); }
    76% {
      -webkit-transform: translateX(-12vw) translateY(32vh) scale(0.53);
              transform: translateX(-12vw) translateY(32vh) scale(0.53); }
    80% {
      -webkit-transform: translateX(-33vw) translateY(-4vh) scale(0.75);
              transform: translateX(-33vw) translateY(-4vh) scale(0.75); }
    84% {
      -webkit-transform: translateX(-19vw) translateY(39vh) scale(0.33);
              transform: translateX(-19vw) translateY(39vh) scale(0.33); }
    88% {
      -webkit-transform: translateX(45vw) translateY(8vh) scale(0.68);
              transform: translateX(45vw) translateY(8vh) scale(0.68); }
    92% {
      -webkit-transform: translateX(8vw) translateY(13vh) scale(0.57);
              transform: translateX(8vw) translateY(13vh) scale(0.57); }
    96% {
      -webkit-transform: translateX(45vw) translateY(2vh) scale(0.7);
              transform: translateX(45vw) translateY(2vh) scale(0.7); }
    100% {
      -webkit-transform: translateX(10vw) translateY(-17vh) scale(0.57);
              transform: translateX(10vw) translateY(-17vh) scale(0.57); } }
  
  @keyframes move2 {
    0% {
      -webkit-transform: translateX(2vw) translateY(7vh) scale(0.83);
              transform: translateX(2vw) translateY(7vh) scale(0.83); }
    4% {
      -webkit-transform: translateX(-3vw) translateY(7vh) scale(0.9);
              transform: translateX(-3vw) translateY(7vh) scale(0.9); }
    8% {
      -webkit-transform: translateX(25vw) translateY(50vh) scale(0.68);
              transform: translateX(25vw) translateY(50vh) scale(0.68); }
    12% {
      -webkit-transform: translateX(35vw) translateY(36vh) scale(0.7);
              transform: translateX(35vw) translateY(36vh) scale(0.7); }
    16% {
      -webkit-transform: translateX(-11vw) translateY(28vh) scale(0.88);
              transform: translateX(-11vw) translateY(28vh) scale(0.88); }
    20% {
      -webkit-transform: translateX(9vw) translateY(5vh) scale(0.58);
              transform: translateX(9vw) translateY(5vh) scale(0.58); }
    24% {
      -webkit-transform: translateX(44vw) translateY(16vh) scale(0.91);
              transform: translateX(44vw) translateY(16vh) scale(0.91); }
    28% {
      -webkit-transform: translateX(2vw) translateY(21vh) scale(0.9);
              transform: translateX(2vw) translateY(21vh) scale(0.9); }
    32% {
      -webkit-transform: translateX(36vw) translateY(-3vh) scale(0.94);
              transform: translateX(36vw) translateY(-3vh) scale(0.94); }
    36% {
      -webkit-transform: translateX(-33vw) translateY(13vh) scale(0.59);
              transform: translateX(-33vw) translateY(13vh) scale(0.59); }
    40% {
      -webkit-transform: translateX(-29vw) translateY(6vh) scale(0.6);
              transform: translateX(-29vw) translateY(6vh) scale(0.6); }
    44% {
      -webkit-transform: translateX(-29vw) translateY(32vh) scale(0.39);
              transform: translateX(-29vw) translateY(32vh) scale(0.39); }
    48% {
      -webkit-transform: translateX(39vw) translateY(-10vh) scale(0.48);
              transform: translateX(39vw) translateY(-10vh) scale(0.48); }
    52% {
      -webkit-transform: translateX(-11vw) translateY(-16vh) scale(0.7);
              transform: translateX(-11vw) translateY(-16vh) scale(0.7); }
    56% {
      -webkit-transform: translateX(48vw) translateY(-39vh) scale(0.46);
              transform: translateX(48vw) translateY(-39vh) scale(0.46); }
    60% {
      -webkit-transform: translateX(30vw) translateY(25vh) scale(0.44);
              transform: translateX(30vw) translateY(25vh) scale(0.44); }
    64% {
      -webkit-transform: translateX(-43vw) translateY(-26vh) scale(0.82);
              transform: translateX(-43vw) translateY(-26vh) scale(0.82); }
    68% {
      -webkit-transform: translateX(11vw) translateY(-1vh) scale(1);
              transform: translateX(11vw) translateY(-1vh) scale(1); }
    72% {
      -webkit-transform: translateX(13vw) translateY(31vh) scale(0.65);
              transform: translateX(13vw) translateY(31vh) scale(0.65); }
    76% {
      -webkit-transform: translateX(-12vw) translateY(32vh) scale(0.53);
              transform: translateX(-12vw) translateY(32vh) scale(0.53); }
    80% {
      -webkit-transform: translateX(-33vw) translateY(-4vh) scale(0.75);
              transform: translateX(-33vw) translateY(-4vh) scale(0.75); }
    84% {
      -webkit-transform: translateX(-19vw) translateY(39vh) scale(0.33);
              transform: translateX(-19vw) translateY(39vh) scale(0.33); }
    88% {
      -webkit-transform: translateX(45vw) translateY(8vh) scale(0.68);
              transform: translateX(45vw) translateY(8vh) scale(0.68); }
    92% {
      -webkit-transform: translateX(8vw) translateY(13vh) scale(0.57);
              transform: translateX(8vw) translateY(13vh) scale(0.57); }
    96% {
      -webkit-transform: translateX(45vw) translateY(2vh) scale(0.7);
              transform: translateX(45vw) translateY(2vh) scale(0.7); }
    100% {
      -webkit-transform: translateX(10vw) translateY(-17vh) scale(0.57);
              transform: translateX(10vw) translateY(-17vh) scale(0.57); } }
  
  .firefly:nth-child(3) {
    -webkit-animation-name: move3;
            animation-name: move3; }
    .firefly:nth-child(3)::before {
      -webkit-animation-duration: 11s;
              animation-duration: 11s; }
    .firefly:nth-child(3)::after {
      -webkit-animation-duration: 11s, 9637ms;
              animation-duration: 11s, 9637ms;
      -webkit-animation-delay: 0ms, 712ms;
              animation-delay: 0ms, 712ms; }
  
  @-webkit-keyframes move3 {
    0% {
      -webkit-transform: translateX(14vw) translateY(-19vh) scale(0.3);
              transform: translateX(14vw) translateY(-19vh) scale(0.3); }
    3.84615% {
      -webkit-transform: translateX(49vw) translateY(34vh) scale(0.26);
              transform: translateX(49vw) translateY(34vh) scale(0.26); }
    7.69231% {
      -webkit-transform: translateX(9vw) translateY(-11vh) scale(0.27);
              transform: translateX(9vw) translateY(-11vh) scale(0.27); }
    11.53846% {
      -webkit-transform: translateX(46vw) translateY(36vh) scale(0.4);
              transform: translateX(46vw) translateY(36vh) scale(0.4); }
    15.38462% {
      -webkit-transform: translateX(-6vw) translateY(7vh) scale(0.62);
              transform: translateX(-6vw) translateY(7vh) scale(0.62); }
    19.23077% {
      -webkit-transform: translateX(15vw) translateY(15vh) scale(0.3);
              transform: translateX(15vw) translateY(15vh) scale(0.3); }
    23.07692% {
      -webkit-transform: translateX(-49vw) translateY(-17vh) scale(0.36);
              transform: translateX(-49vw) translateY(-17vh) scale(0.36); }
    26.92308% {
      -webkit-transform: translateX(-4vw) translateY(5vh) scale(0.85);
              transform: translateX(-4vw) translateY(5vh) scale(0.85); }
    30.76923% {
      -webkit-transform: translateX(-42vw) translateY(10vh) scale(0.76);
              transform: translateX(-42vw) translateY(10vh) scale(0.76); }
    34.61538% {
      -webkit-transform: translateX(20vw) translateY(-23vh) scale(0.47);
              transform: translateX(20vw) translateY(-23vh) scale(0.47); }
    38.46154% {
      -webkit-transform: translateX(-5vw) translateY(42vh) scale(0.58);
              transform: translateX(-5vw) translateY(42vh) scale(0.58); }
    42.30769% {
      -webkit-transform: translateX(-35vw) translateY(5vh) scale(0.8);
              transform: translateX(-35vw) translateY(5vh) scale(0.8); }
    46.15385% {
      -webkit-transform: translateX(7vw) translateY(-45vh) scale(0.39);
              transform: translateX(7vw) translateY(-45vh) scale(0.39); }
    50% {
      -webkit-transform: translateX(24vw) translateY(17vh) scale(0.98);
              transform: translateX(24vw) translateY(17vh) scale(0.98); }
    53.84615% {
      -webkit-transform: translateX(-10vw) translateY(-24vh) scale(0.39);
              transform: translateX(-10vw) translateY(-24vh) scale(0.39); }
    57.69231% {
      -webkit-transform: translateX(50vw) translateY(10vh) scale(0.61);
              transform: translateX(50vw) translateY(10vh) scale(0.61); }
    61.53846% {
      -webkit-transform: translateX(-1vw) translateY(-1vh) scale(0.85);
              transform: translateX(-1vw) translateY(-1vh) scale(0.85); }
    65.38462% {
      -webkit-transform: translateX(-38vw) translateY(-26vh) scale(0.64);
              transform: translateX(-38vw) translateY(-26vh) scale(0.64); }
    69.23077% {
      -webkit-transform: translateX(-43vw) translateY(-38vh) scale(0.7);
              transform: translateX(-43vw) translateY(-38vh) scale(0.7); }
    73.07692% {
      -webkit-transform: translateX(12vw) translateY(45vh) scale(0.99);
              transform: translateX(12vw) translateY(45vh) scale(0.99); }
    76.92308% {
      -webkit-transform: translateX(27vw) translateY(30vh) scale(0.95);
              transform: translateX(27vw) translateY(30vh) scale(0.95); }
    80.76923% {
      -webkit-transform: translateX(-13vw) translateY(46vh) scale(0.35);
              transform: translateX(-13vw) translateY(46vh) scale(0.35); }
    84.61538% {
      -webkit-transform: translateX(1vw) translateY(-1vh) scale(0.66);
              transform: translateX(1vw) translateY(-1vh) scale(0.66); }
    88.46154% {
      -webkit-transform: translateX(7vw) translateY(-9vh) scale(0.84);
              transform: translateX(7vw) translateY(-9vh) scale(0.84); }
    92.30769% {
      -webkit-transform: translateX(-19vw) translateY(-49vh) scale(0.94);
              transform: translateX(-19vw) translateY(-49vh) scale(0.94); }
    96.15385% {
      -webkit-transform: translateX(-36vw) translateY(38vh) scale(0.4);
              transform: translateX(-36vw) translateY(38vh) scale(0.4); }
    100% {
      -webkit-transform: translateX(10vw) translateY(0vh) scale(0.57);
              transform: translateX(10vw) translateY(0vh) scale(0.57); } }
  
  @keyframes move3 {
    0% {
      -webkit-transform: translateX(14vw) translateY(-19vh) scale(0.3);
              transform: translateX(14vw) translateY(-19vh) scale(0.3); }
    3.84615% {
      -webkit-transform: translateX(49vw) translateY(34vh) scale(0.26);
              transform: translateX(49vw) translateY(34vh) scale(0.26); }
    7.69231% {
      -webkit-transform: translateX(9vw) translateY(-11vh) scale(0.27);
              transform: translateX(9vw) translateY(-11vh) scale(0.27); }
    11.53846% {
      -webkit-transform: translateX(46vw) translateY(36vh) scale(0.4);
              transform: translateX(46vw) translateY(36vh) scale(0.4); }
    15.38462% {
      -webkit-transform: translateX(-6vw) translateY(7vh) scale(0.62);
              transform: translateX(-6vw) translateY(7vh) scale(0.62); }
    19.23077% {
      -webkit-transform: translateX(15vw) translateY(15vh) scale(0.3);
              transform: translateX(15vw) translateY(15vh) scale(0.3); }
    23.07692% {
      -webkit-transform: translateX(-49vw) translateY(-17vh) scale(0.36);
              transform: translateX(-49vw) translateY(-17vh) scale(0.36); }
    26.92308% {
      -webkit-transform: translateX(-4vw) translateY(5vh) scale(0.85);
              transform: translateX(-4vw) translateY(5vh) scale(0.85); }
    30.76923% {
      -webkit-transform: translateX(-42vw) translateY(10vh) scale(0.76);
              transform: translateX(-42vw) translateY(10vh) scale(0.76); }
    34.61538% {
      -webkit-transform: translateX(20vw) translateY(-23vh) scale(0.47);
              transform: translateX(20vw) translateY(-23vh) scale(0.47); }
    38.46154% {
      -webkit-transform: translateX(-5vw) translateY(42vh) scale(0.58);
              transform: translateX(-5vw) translateY(42vh) scale(0.58); }
    42.30769% {
      -webkit-transform: translateX(-35vw) translateY(5vh) scale(0.8);
              transform: translateX(-35vw) translateY(5vh) scale(0.8); }
    46.15385% {
      -webkit-transform: translateX(7vw) translateY(-45vh) scale(0.39);
              transform: translateX(7vw) translateY(-45vh) scale(0.39); }
    50% {
      -webkit-transform: translateX(24vw) translateY(17vh) scale(0.98);
              transform: translateX(24vw) translateY(17vh) scale(0.98); }
    53.84615% {
      -webkit-transform: translateX(-10vw) translateY(-24vh) scale(0.39);
              transform: translateX(-10vw) translateY(-24vh) scale(0.39); }
    57.69231% {
      -webkit-transform: translateX(50vw) translateY(10vh) scale(0.61);
              transform: translateX(50vw) translateY(10vh) scale(0.61); }
    61.53846% {
      -webkit-transform: translateX(-1vw) translateY(-1vh) scale(0.85);
              transform: translateX(-1vw) translateY(-1vh) scale(0.85); }
    65.38462% {
      -webkit-transform: translateX(-38vw) translateY(-26vh) scale(0.64);
              transform: translateX(-38vw) translateY(-26vh) scale(0.64); }
    69.23077% {
      -webkit-transform: translateX(-43vw) translateY(-38vh) scale(0.7);
              transform: translateX(-43vw) translateY(-38vh) scale(0.7); }
    73.07692% {
      -webkit-transform: translateX(12vw) translateY(45vh) scale(0.99);
              transform: translateX(12vw) translateY(45vh) scale(0.99); }
    76.92308% {
      -webkit-transform: translateX(27vw) translateY(30vh) scale(0.95);
              transform: translateX(27vw) translateY(30vh) scale(0.95); }
    80.76923% {
      -webkit-transform: translateX(-13vw) translateY(46vh) scale(0.35);
              transform: translateX(-13vw) translateY(46vh) scale(0.35); }
    84.61538% {
      -webkit-transform: translateX(1vw) translateY(-1vh) scale(0.66);
              transform: translateX(1vw) translateY(-1vh) scale(0.66); }
    88.46154% {
      -webkit-transform: translateX(7vw) translateY(-9vh) scale(0.84);
              transform: translateX(7vw) translateY(-9vh) scale(0.84); }
    92.30769% {
      -webkit-transform: translateX(-19vw) translateY(-49vh) scale(0.94);
              transform: translateX(-19vw) translateY(-49vh) scale(0.94); }
    96.15385% {
      -webkit-transform: translateX(-36vw) translateY(38vh) scale(0.4);
              transform: translateX(-36vw) translateY(38vh) scale(0.4); }
    100% {
      -webkit-transform: translateX(10vw) translateY(0vh) scale(0.57);
              transform: translateX(10vw) translateY(0vh) scale(0.57); } }
  
  .firefly:nth-child(4) {
    -webkit-animation-name: move4;
            animation-name: move4; }
    .firefly:nth-child(4)::before {
      -webkit-animation-duration: 10s;
              animation-duration: 10s; }
    .firefly:nth-child(4)::after {
      -webkit-animation-duration: 10s, 5986ms;
              animation-duration: 10s, 5986ms;
      -webkit-animation-delay: 0ms, 6648ms;
              animation-delay: 0ms, 6648ms; }
  
  @-webkit-keyframes move4 {
    0% {
      -webkit-transform: translateX(34vw) translateY(42vh) scale(0.49);
              transform: translateX(34vw) translateY(42vh) scale(0.49); }
    4.7619% {
      -webkit-transform: translateX(11vw) translateY(25vh) scale(0.74);
              transform: translateX(11vw) translateY(25vh) scale(0.74); }
    9.52381% {
      -webkit-transform: translateX(-8vw) translateY(-19vh) scale(0.74);
              transform: translateX(-8vw) translateY(-19vh) scale(0.74); }
    14.28571% {
      -webkit-transform: translateX(-36vw) translateY(-12vh) scale(0.41);
              transform: translateX(-36vw) translateY(-12vh) scale(0.41); }
    19.04762% {
      -webkit-transform: translateX(-39vw) translateY(-30vh) scale(0.37);
              transform: translateX(-39vw) translateY(-30vh) scale(0.37); }
    23.80952% {
      -webkit-transform: translateX(-4vw) translateY(13vh) scale(0.66);
              transform: translateX(-4vw) translateY(13vh) scale(0.66); }
    28.57143% {
      -webkit-transform: translateX(-39vw) translateY(-28vh) scale(0.79);
              transform: translateX(-39vw) translateY(-28vh) scale(0.79); }
    33.33333% {
      -webkit-transform: translateX(23vw) translateY(-39vh) scale(0.67);
              transform: translateX(23vw) translateY(-39vh) scale(0.67); }
    38.09524% {
      -webkit-transform: translateX(50vw) translateY(-41vh) scale(0.35);
              transform: translateX(50vw) translateY(-41vh) scale(0.35); }
    42.85714% {
      -webkit-transform: translateX(-44vw) translateY(-20vh) scale(0.88);
              transform: translateX(-44vw) translateY(-20vh) scale(0.88); }
    47.61905% {
      -webkit-transform: translateX(40vw) translateY(-48vh) scale(0.43);
              transform: translateX(40vw) translateY(-48vh) scale(0.43); }
    52.38095% {
      -webkit-transform: translateX(-34vw) translateY(27vh) scale(0.93);
              transform: translateX(-34vw) translateY(27vh) scale(0.93); }
    57.14286% {
      -webkit-transform: translateX(-40vw) translateY(8vh) scale(0.5);
              transform: translateX(-40vw) translateY(8vh) scale(0.5); }
    61.90476% {
      -webkit-transform: translateX(38vw) translateY(33vh) scale(0.31);
              transform: translateX(38vw) translateY(33vh) scale(0.31); }
    66.66667% {
      -webkit-transform: translateX(-34vw) translateY(34vh) scale(0.81);
              transform: translateX(-34vw) translateY(34vh) scale(0.81); }
    71.42857% {
      -webkit-transform: translateX(1vw) translateY(42vh) scale(0.88);
              transform: translateX(1vw) translateY(42vh) scale(0.88); }
    76.19048% {
      -webkit-transform: translateX(49vw) translateY(1vh) scale(0.52);
              transform: translateX(49vw) translateY(1vh) scale(0.52); }
    80.95238% {
      -webkit-transform: translateX(3vw) translateY(-44vh) scale(0.35);
              transform: translateX(3vw) translateY(-44vh) scale(0.35); }
    85.71429% {
      -webkit-transform: translateX(21vw) translateY(-42vh) scale(0.59);
              transform: translateX(21vw) translateY(-42vh) scale(0.59); }
    90.47619% {
      -webkit-transform: translateX(2vw) translateY(36vh) scale(0.7);
              transform: translateX(2vw) translateY(36vh) scale(0.7); }
    95.2381% {
      -webkit-transform: translateX(26vw) translateY(-15vh) scale(0.32);
              transform: translateX(26vw) translateY(-15vh) scale(0.32); }
    100% {
      -webkit-transform: translateX(31vw) translateY(-5vh) scale(0.39);
              transform: translateX(31vw) translateY(-5vh) scale(0.39); } }
  
  @keyframes move4 {
    0% {
      -webkit-transform: translateX(34vw) translateY(42vh) scale(0.49);
              transform: translateX(34vw) translateY(42vh) scale(0.49); }
    4.7619% {
      -webkit-transform: translateX(11vw) translateY(25vh) scale(0.74);
              transform: translateX(11vw) translateY(25vh) scale(0.74); }
    9.52381% {
      -webkit-transform: translateX(-8vw) translateY(-19vh) scale(0.74);
              transform: translateX(-8vw) translateY(-19vh) scale(0.74); }
    14.28571% {
      -webkit-transform: translateX(-36vw) translateY(-12vh) scale(0.41);
              transform: translateX(-36vw) translateY(-12vh) scale(0.41); }
    19.04762% {
      -webkit-transform: translateX(-39vw) translateY(-30vh) scale(0.37);
              transform: translateX(-39vw) translateY(-30vh) scale(0.37); }
    23.80952% {
      -webkit-transform: translateX(-4vw) translateY(13vh) scale(0.66);
              transform: translateX(-4vw) translateY(13vh) scale(0.66); }
    28.57143% {
      -webkit-transform: translateX(-39vw) translateY(-28vh) scale(0.79);
              transform: translateX(-39vw) translateY(-28vh) scale(0.79); }
    33.33333% {
      -webkit-transform: translateX(23vw) translateY(-39vh) scale(0.67);
              transform: translateX(23vw) translateY(-39vh) scale(0.67); }
    38.09524% {
      -webkit-transform: translateX(50vw) translateY(-41vh) scale(0.35);
              transform: translateX(50vw) translateY(-41vh) scale(0.35); }
    42.85714% {
      -webkit-transform: translateX(-44vw) translateY(-20vh) scale(0.88);
              transform: translateX(-44vw) translateY(-20vh) scale(0.88); }
    47.61905% {
      -webkit-transform: translateX(40vw) translateY(-48vh) scale(0.43);
              transform: translateX(40vw) translateY(-48vh) scale(0.43); }
    52.38095% {
      -webkit-transform: translateX(-34vw) translateY(27vh) scale(0.93);
              transform: translateX(-34vw) translateY(27vh) scale(0.93); }
    57.14286% {
      -webkit-transform: translateX(-40vw) translateY(8vh) scale(0.5);
              transform: translateX(-40vw) translateY(8vh) scale(0.5); }
    61.90476% {
      -webkit-transform: translateX(38vw) translateY(33vh) scale(0.31);
              transform: translateX(38vw) translateY(33vh) scale(0.31); }
    66.66667% {
      -webkit-transform: translateX(-34vw) translateY(34vh) scale(0.81);
              transform: translateX(-34vw) translateY(34vh) scale(0.81); }
    71.42857% {
      -webkit-transform: translateX(1vw) translateY(42vh) scale(0.88);
              transform: translateX(1vw) translateY(42vh) scale(0.88); }
    76.19048% {
      -webkit-transform: translateX(49vw) translateY(1vh) scale(0.52);
              transform: translateX(49vw) translateY(1vh) scale(0.52); }
    80.95238% {
      -webkit-transform: translateX(3vw) translateY(-44vh) scale(0.35);
              transform: translateX(3vw) translateY(-44vh) scale(0.35); }
    85.71429% {
      -webkit-transform: translateX(21vw) translateY(-42vh) scale(0.59);
              transform: translateX(21vw) translateY(-42vh) scale(0.59); }
    90.47619% {
      -webkit-transform: translateX(2vw) translateY(36vh) scale(0.7);
              transform: translateX(2vw) translateY(36vh) scale(0.7); }
    95.2381% {
      -webkit-transform: translateX(26vw) translateY(-15vh) scale(0.32);
              transform: translateX(26vw) translateY(-15vh) scale(0.32); }
    100% {
      -webkit-transform: translateX(31vw) translateY(-5vh) scale(0.39);
              transform: translateX(31vw) translateY(-5vh) scale(0.39); } }
  
  .firefly:nth-child(5) {
    -webkit-animation-name: move5;
            animation-name: move5; }
    .firefly:nth-child(5)::before {
      -webkit-animation-duration: 17s;
              animation-duration: 17s; }
    .firefly:nth-child(5)::after {
      -webkit-animation-duration: 17s, 8428ms;
              animation-duration: 17s, 8428ms;
      -webkit-animation-delay: 0ms, 6344ms;
              animation-delay: 0ms, 6344ms; }
  
  @-webkit-keyframes move5 {
    0% {
      -webkit-transform: translateX(13vw) translateY(15vh) scale(0.47);
              transform: translateX(13vw) translateY(15vh) scale(0.47); }
    5.55556% {
      -webkit-transform: translateX(-32vw) translateY(17vh) scale(0.59);
              transform: translateX(-32vw) translateY(17vh) scale(0.59); }
    11.11111% {
      -webkit-transform: translateX(-44vw) translateY(-41vh) scale(0.74);
              transform: translateX(-44vw) translateY(-41vh) scale(0.74); }
    16.66667% {
      -webkit-transform: translateX(25vw) translateY(-29vh) scale(0.69);
              transform: translateX(25vw) translateY(-29vh) scale(0.69); }
    22.22222% {
      -webkit-transform: translateX(-38vw) translateY(-46vh) scale(0.97);
              transform: translateX(-38vw) translateY(-46vh) scale(0.97); }
    27.77778% {
      -webkit-transform: translateX(24vw) translateY(-26vh) scale(0.8);
              transform: translateX(24vw) translateY(-26vh) scale(0.8); }
    33.33333% {
      -webkit-transform: translateX(-45vw) translateY(-40vh) scale(0.94);
              transform: translateX(-45vw) translateY(-40vh) scale(0.94); }
    38.88889% {
      -webkit-transform: translateX(10vw) translateY(18vh) scale(0.66);
              transform: translateX(10vw) translateY(18vh) scale(0.66); }
    44.44444% {
      -webkit-transform: translateX(-18vw) translateY(-14vh) scale(0.68);
              transform: translateX(-18vw) translateY(-14vh) scale(0.68); }
    50% {
      -webkit-transform: translateX(-2vw) translateY(40vh) scale(0.71);
              transform: translateX(-2vw) translateY(40vh) scale(0.71); }
    55.55556% {
      -webkit-transform: translateX(0vw) translateY(-4vh) scale(0.36);
              transform: translateX(0vw) translateY(-4vh) scale(0.36); }
    61.11111% {
      -webkit-transform: translateX(-35vw) translateY(19vh) scale(0.9);
              transform: translateX(-35vw) translateY(19vh) scale(0.9); }
    66.66667% {
      -webkit-transform: translateX(19vw) translateY(-49vh) scale(0.34);
              transform: translateX(19vw) translateY(-49vh) scale(0.34); }
    72.22222% {
      -webkit-transform: translateX(-28vw) translateY(26vh) scale(0.74);
              transform: translateX(-28vw) translateY(26vh) scale(0.74); }
    77.77778% {
      -webkit-transform: translateX(-7vw) translateY(-41vh) scale(0.65);
              transform: translateX(-7vw) translateY(-41vh) scale(0.65); }
    83.33333% {
      -webkit-transform: translateX(-11vw) translateY(10vh) scale(0.38);
              transform: translateX(-11vw) translateY(10vh) scale(0.38); }
    88.88889% {
      -webkit-transform: translateX(-38vw) translateY(-32vh) scale(0.33);
              transform: translateX(-38vw) translateY(-32vh) scale(0.33); }
    94.44444% {
      -webkit-transform: translateX(-10vw) translateY(-45vh) scale(0.48);
              transform: translateX(-10vw) translateY(-45vh) scale(0.48); }
    100% {
      -webkit-transform: translateX(4vw) translateY(25vh) scale(0.5);
              transform: translateX(4vw) translateY(25vh) scale(0.5); } }
  
  @keyframes move5 {
    0% {
      -webkit-transform: translateX(13vw) translateY(15vh) scale(0.47);
              transform: translateX(13vw) translateY(15vh) scale(0.47); }
    5.55556% {
      -webkit-transform: translateX(-32vw) translateY(17vh) scale(0.59);
              transform: translateX(-32vw) translateY(17vh) scale(0.59); }
    11.11111% {
      -webkit-transform: translateX(-44vw) translateY(-41vh) scale(0.74);
              transform: translateX(-44vw) translateY(-41vh) scale(0.74); }
    16.66667% {
      -webkit-transform: translateX(25vw) translateY(-29vh) scale(0.69);
              transform: translateX(25vw) translateY(-29vh) scale(0.69); }
    22.22222% {
      -webkit-transform: translateX(-38vw) translateY(-46vh) scale(0.97);
              transform: translateX(-38vw) translateY(-46vh) scale(0.97); }
    27.77778% {
      -webkit-transform: translateX(24vw) translateY(-26vh) scale(0.8);
              transform: translateX(24vw) translateY(-26vh) scale(0.8); }
    33.33333% {
      -webkit-transform: translateX(-45vw) translateY(-40vh) scale(0.94);
              transform: translateX(-45vw) translateY(-40vh) scale(0.94); }
    38.88889% {
      -webkit-transform: translateX(10vw) translateY(18vh) scale(0.66);
              transform: translateX(10vw) translateY(18vh) scale(0.66); }
    44.44444% {
      -webkit-transform: translateX(-18vw) translateY(-14vh) scale(0.68);
              transform: translateX(-18vw) translateY(-14vh) scale(0.68); }
    50% {
      -webkit-transform: translateX(-2vw) translateY(40vh) scale(0.71);
              transform: translateX(-2vw) translateY(40vh) scale(0.71); }
    55.55556% {
      -webkit-transform: translateX(0vw) translateY(-4vh) scale(0.36);
              transform: translateX(0vw) translateY(-4vh) scale(0.36); }
    61.11111% {
      -webkit-transform: translateX(-35vw) translateY(19vh) scale(0.9);
              transform: translateX(-35vw) translateY(19vh) scale(0.9); }
    66.66667% {
      -webkit-transform: translateX(19vw) translateY(-49vh) scale(0.34);
              transform: translateX(19vw) translateY(-49vh) scale(0.34); }
    72.22222% {
      -webkit-transform: translateX(-28vw) translateY(26vh) scale(0.74);
              transform: translateX(-28vw) translateY(26vh) scale(0.74); }
    77.77778% {
      -webkit-transform: translateX(-7vw) translateY(-41vh) scale(0.65);
              transform: translateX(-7vw) translateY(-41vh) scale(0.65); }
    83.33333% {
      -webkit-transform: translateX(-11vw) translateY(10vh) scale(0.38);
              transform: translateX(-11vw) translateY(10vh) scale(0.38); }
    88.88889% {
      -webkit-transform: translateX(-38vw) translateY(-32vh) scale(0.33);
              transform: translateX(-38vw) translateY(-32vh) scale(0.33); }
    94.44444% {
      -webkit-transform: translateX(-10vw) translateY(-45vh) scale(0.48);
              transform: translateX(-10vw) translateY(-45vh) scale(0.48); }
    100% {
      -webkit-transform: translateX(4vw) translateY(25vh) scale(0.5);
              transform: translateX(4vw) translateY(25vh) scale(0.5); } }
  
  .firefly:nth-child(6) {
    -webkit-animation-name: move6;
            animation-name: move6; }
    .firefly:nth-child(6)::before {
      -webkit-animation-duration: 18s;
              animation-duration: 18s; }
    .firefly:nth-child(6)::after {
      -webkit-animation-duration: 18s, 7569ms;
              animation-duration: 18s, 7569ms;
      -webkit-animation-delay: 0ms, 3134ms;
              animation-delay: 0ms, 3134ms; }
  
  @-webkit-keyframes move6 {
    0% {
      -webkit-transform: translateX(-10vw) translateY(36vh) scale(0.3);
              transform: translateX(-10vw) translateY(36vh) scale(0.3); }
    5.26316% {
      -webkit-transform: translateX(-3vw) translateY(-21vh) scale(0.66);
              transform: translateX(-3vw) translateY(-21vh) scale(0.66); }
    10.52632% {
      -webkit-transform: translateX(42vw) translateY(33vh) scale(0.97);
              transform: translateX(42vw) translateY(33vh) scale(0.97); }
    15.78947% {
      -webkit-transform: translateX(-40vw) translateY(-18vh) scale(0.3);
              transform: translateX(-40vw) translateY(-18vh) scale(0.3); }
    21.05263% {
      -webkit-transform: translateX(-20vw) translateY(31vh) scale(0.6);
              transform: translateX(-20vw) translateY(31vh) scale(0.6); }
    26.31579% {
      -webkit-transform: translateX(-45vw) translateY(14vh) scale(0.99);
              transform: translateX(-45vw) translateY(14vh) scale(0.99); }
    31.57895% {
      -webkit-transform: translateX(1vw) translateY(-48vh) scale(0.38);
              transform: translateX(1vw) translateY(-48vh) scale(0.38); }
    36.84211% {
      -webkit-transform: translateX(18vw) translateY(27vh) scale(0.93);
              transform: translateX(18vw) translateY(27vh) scale(0.93); }
    42.10526% {
      -webkit-transform: translateX(-9vw) translateY(20vh) scale(0.34);
              transform: translateX(-9vw) translateY(20vh) scale(0.34); }
    47.36842% {
      -webkit-transform: translateX(32vw) translateY(29vh) scale(0.61);
              transform: translateX(32vw) translateY(29vh) scale(0.61); }
    52.63158% {
      -webkit-transform: translateX(-13vw) translateY(26vh) scale(0.63);
              transform: translateX(-13vw) translateY(26vh) scale(0.63); }
    57.89474% {
      -webkit-transform: translateX(23vw) translateY(40vh) scale(0.7);
              transform: translateX(23vw) translateY(40vh) scale(0.7); }
    63.15789% {
      -webkit-transform: translateX(2vw) translateY(39vh) scale(0.5);
              transform: translateX(2vw) translateY(39vh) scale(0.5); }
    68.42105% {
      -webkit-transform: translateX(-4vw) translateY(-11vh) scale(0.61);
              transform: translateX(-4vw) translateY(-11vh) scale(0.61); }
    73.68421% {
      -webkit-transform: translateX(-8vw) translateY(9vh) scale(0.65);
              transform: translateX(-8vw) translateY(9vh) scale(0.65); }
    78.94737% {
      -webkit-transform: translateX(-11vw) translateY(-34vh) scale(0.33);
              transform: translateX(-11vw) translateY(-34vh) scale(0.33); }
    84.21053% {
      -webkit-transform: translateX(-30vw) translateY(-5vh) scale(0.51);
              transform: translateX(-30vw) translateY(-5vh) scale(0.51); }
    89.47368% {
      -webkit-transform: translateX(19vw) translateY(16vh) scale(0.39);
              transform: translateX(19vw) translateY(16vh) scale(0.39); }
    94.73684% {
      -webkit-transform: translateX(43vw) translateY(4vh) scale(0.86);
              transform: translateX(43vw) translateY(4vh) scale(0.86); }
    100% {
      -webkit-transform: translateX(34vw) translateY(45vh) scale(0.82);
              transform: translateX(34vw) translateY(45vh) scale(0.82); } }
  
  @keyframes move6 {
    0% {
      -webkit-transform: translateX(-10vw) translateY(36vh) scale(0.3);
              transform: translateX(-10vw) translateY(36vh) scale(0.3); }
    5.26316% {
      -webkit-transform: translateX(-3vw) translateY(-21vh) scale(0.66);
              transform: translateX(-3vw) translateY(-21vh) scale(0.66); }
    10.52632% {
      -webkit-transform: translateX(42vw) translateY(33vh) scale(0.97);
              transform: translateX(42vw) translateY(33vh) scale(0.97); }
    15.78947% {
      -webkit-transform: translateX(-40vw) translateY(-18vh) scale(0.3);
              transform: translateX(-40vw) translateY(-18vh) scale(0.3); }
    21.05263% {
      -webkit-transform: translateX(-20vw) translateY(31vh) scale(0.6);
              transform: translateX(-20vw) translateY(31vh) scale(0.6); }
    26.31579% {
      -webkit-transform: translateX(-45vw) translateY(14vh) scale(0.99);
              transform: translateX(-45vw) translateY(14vh) scale(0.99); }
    31.57895% {
      -webkit-transform: translateX(1vw) translateY(-48vh) scale(0.38);
              transform: translateX(1vw) translateY(-48vh) scale(0.38); }
    36.84211% {
      -webkit-transform: translateX(18vw) translateY(27vh) scale(0.93);
              transform: translateX(18vw) translateY(27vh) scale(0.93); }
    42.10526% {
      -webkit-transform: translateX(-9vw) translateY(20vh) scale(0.34);
              transform: translateX(-9vw) translateY(20vh) scale(0.34); }
    47.36842% {
      -webkit-transform: translateX(32vw) translateY(29vh) scale(0.61);
              transform: translateX(32vw) translateY(29vh) scale(0.61); }
    52.63158% {
      -webkit-transform: translateX(-13vw) translateY(26vh) scale(0.63);
              transform: translateX(-13vw) translateY(26vh) scale(0.63); }
    57.89474% {
      -webkit-transform: translateX(23vw) translateY(40vh) scale(0.7);
              transform: translateX(23vw) translateY(40vh) scale(0.7); }
    63.15789% {
      -webkit-transform: translateX(2vw) translateY(39vh) scale(0.5);
              transform: translateX(2vw) translateY(39vh) scale(0.5); }
    68.42105% {
      -webkit-transform: translateX(-4vw) translateY(-11vh) scale(0.61);
              transform: translateX(-4vw) translateY(-11vh) scale(0.61); }
    73.68421% {
      -webkit-transform: translateX(-8vw) translateY(9vh) scale(0.65);
              transform: translateX(-8vw) translateY(9vh) scale(0.65); }
    78.94737% {
      -webkit-transform: translateX(-11vw) translateY(-34vh) scale(0.33);
              transform: translateX(-11vw) translateY(-34vh) scale(0.33); }
    84.21053% {
      -webkit-transform: translateX(-30vw) translateY(-5vh) scale(0.51);
              transform: translateX(-30vw) translateY(-5vh) scale(0.51); }
    89.47368% {
      -webkit-transform: translateX(19vw) translateY(16vh) scale(0.39);
              transform: translateX(19vw) translateY(16vh) scale(0.39); }
    94.73684% {
      -webkit-transform: translateX(43vw) translateY(4vh) scale(0.86);
              transform: translateX(43vw) translateY(4vh) scale(0.86); }
    100% {
      -webkit-transform: translateX(34vw) translateY(45vh) scale(0.82);
              transform: translateX(34vw) translateY(45vh) scale(0.82); } }
  
  .firefly:nth-child(7) {
    -webkit-animation-name: move7;
            animation-name: move7; }
    .firefly:nth-child(7)::before {
      -webkit-animation-duration: 16s;
              animation-duration: 16s; }
    .firefly:nth-child(7)::after {
      -webkit-animation-duration: 16s, 10286ms;
              animation-duration: 16s, 10286ms;
      -webkit-animation-delay: 0ms, 7514ms;
              animation-delay: 0ms, 7514ms; }
  
  @-webkit-keyframes move7 {
    0% {
      -webkit-transform: translateX(15vw) translateY(7vh) scale(1);
              transform: translateX(15vw) translateY(7vh) scale(1); }
    4.34783% {
      -webkit-transform: translateX(48vw) translateY(-11vh) scale(0.29);
              transform: translateX(48vw) translateY(-11vh) scale(0.29); }
    8.69565% {
      -webkit-transform: translateX(-23vw) translateY(-43vh) scale(0.48);
              transform: translateX(-23vw) translateY(-43vh) scale(0.48); }
    13.04348% {
      -webkit-transform: translateX(-29vw) translateY(-9vh) scale(0.9);
              transform: translateX(-29vw) translateY(-9vh) scale(0.9); }
    17.3913% {
      -webkit-transform: translateX(-26vw) translateY(40vh) scale(0.93);
              transform: translateX(-26vw) translateY(40vh) scale(0.93); }
    21.73913% {
      -webkit-transform: translateX(39vw) translateY(46vh) scale(0.74);
              transform: translateX(39vw) translateY(46vh) scale(0.74); }
    26.08696% {
      -webkit-transform: translateX(-9vw) translateY(-42vh) scale(0.36);
              transform: translateX(-9vw) translateY(-42vh) scale(0.36); }
    30.43478% {
      -webkit-transform: translateX(-8vw) translateY(42vh) scale(0.67);
              transform: translateX(-8vw) translateY(42vh) scale(0.67); }
    34.78261% {
      -webkit-transform: translateX(-45vw) translateY(-30vh) scale(0.47);
              transform: translateX(-45vw) translateY(-30vh) scale(0.47); }
    39.13043% {
      -webkit-transform: translateX(-39vw) translateY(34vh) scale(0.83);
              transform: translateX(-39vw) translateY(34vh) scale(0.83); }
    43.47826% {
      -webkit-transform: translateX(19vw) translateY(22vh) scale(0.99);
              transform: translateX(19vw) translateY(22vh) scale(0.99); }
    47.82609% {
      -webkit-transform: translateX(-21vw) translateY(6vh) scale(0.7);
              transform: translateX(-21vw) translateY(6vh) scale(0.7); }
    52.17391% {
      -webkit-transform: translateX(-24vw) translateY(1vh) scale(0.5);
              transform: translateX(-24vw) translateY(1vh) scale(0.5); }
    56.52174% {
      -webkit-transform: translateX(41vw) translateY(41vh) scale(0.8);
              transform: translateX(41vw) translateY(41vh) scale(0.8); }
    60.86957% {
      -webkit-transform: translateX(-31vw) translateY(-27vh) scale(0.58);
              transform: translateX(-31vw) translateY(-27vh) scale(0.58); }
    65.21739% {
      -webkit-transform: translateX(-47vw) translateY(-20vh) scale(0.49);
              transform: translateX(-47vw) translateY(-20vh) scale(0.49); }
    69.56522% {
      -webkit-transform: translateX(9vw) translateY(-2vh) scale(0.5);
              transform: translateX(9vw) translateY(-2vh) scale(0.5); }
    73.91304% {
      -webkit-transform: translateX(-41vw) translateY(39vh) scale(0.57);
              transform: translateX(-41vw) translateY(39vh) scale(0.57); }
    78.26087% {
      -webkit-transform: translateX(11vw) translateY(33vh) scale(0.26);
              transform: translateX(11vw) translateY(33vh) scale(0.26); }
    82.6087% {
      -webkit-transform: translateX(-31vw) translateY(43vh) scale(0.89);
              transform: translateX(-31vw) translateY(43vh) scale(0.89); }
    86.95652% {
      -webkit-transform: translateX(3vw) translateY(46vh) scale(0.45);
              transform: translateX(3vw) translateY(46vh) scale(0.45); }
    91.30435% {
      -webkit-transform: translateX(14vw) translateY(24vh) scale(0.43);
              transform: translateX(14vw) translateY(24vh) scale(0.43); }
    95.65217% {
      -webkit-transform: translateX(-8vw) translateY(-28vh) scale(0.41);
              transform: translateX(-8vw) translateY(-28vh) scale(0.41); }
    100% {
      -webkit-transform: translateX(4vw) translateY(-42vh) scale(0.41);
              transform: translateX(4vw) translateY(-42vh) scale(0.41); } }
  
  @keyframes move7 {
    0% {
      -webkit-transform: translateX(15vw) translateY(7vh) scale(1);
              transform: translateX(15vw) translateY(7vh) scale(1); }
    4.34783% {
      -webkit-transform: translateX(48vw) translateY(-11vh) scale(0.29);
              transform: translateX(48vw) translateY(-11vh) scale(0.29); }
    8.69565% {
      -webkit-transform: translateX(-23vw) translateY(-43vh) scale(0.48);
              transform: translateX(-23vw) translateY(-43vh) scale(0.48); }
    13.04348% {
      -webkit-transform: translateX(-29vw) translateY(-9vh) scale(0.9);
              transform: translateX(-29vw) translateY(-9vh) scale(0.9); }
    17.3913% {
      -webkit-transform: translateX(-26vw) translateY(40vh) scale(0.93);
              transform: translateX(-26vw) translateY(40vh) scale(0.93); }
    21.73913% {
      -webkit-transform: translateX(39vw) translateY(46vh) scale(0.74);
              transform: translateX(39vw) translateY(46vh) scale(0.74); }
    26.08696% {
      -webkit-transform: translateX(-9vw) translateY(-42vh) scale(0.36);
              transform: translateX(-9vw) translateY(-42vh) scale(0.36); }
    30.43478% {
      -webkit-transform: translateX(-8vw) translateY(42vh) scale(0.67);
              transform: translateX(-8vw) translateY(42vh) scale(0.67); }
    34.78261% {
      -webkit-transform: translateX(-45vw) translateY(-30vh) scale(0.47);
              transform: translateX(-45vw) translateY(-30vh) scale(0.47); }
    39.13043% {
      -webkit-transform: translateX(-39vw) translateY(34vh) scale(0.83);
              transform: translateX(-39vw) translateY(34vh) scale(0.83); }
    43.47826% {
      -webkit-transform: translateX(19vw) translateY(22vh) scale(0.99);
              transform: translateX(19vw) translateY(22vh) scale(0.99); }
    47.82609% {
      -webkit-transform: translateX(-21vw) translateY(6vh) scale(0.7);
              transform: translateX(-21vw) translateY(6vh) scale(0.7); }
    52.17391% {
      -webkit-transform: translateX(-24vw) translateY(1vh) scale(0.5);
              transform: translateX(-24vw) translateY(1vh) scale(0.5); }
    56.52174% {
      -webkit-transform: translateX(41vw) translateY(41vh) scale(0.8);
              transform: translateX(41vw) translateY(41vh) scale(0.8); }
    60.86957% {
      -webkit-transform: translateX(-31vw) translateY(-27vh) scale(0.58);
              transform: translateX(-31vw) translateY(-27vh) scale(0.58); }
    65.21739% {
      -webkit-transform: translateX(-47vw) translateY(-20vh) scale(0.49);
              transform: translateX(-47vw) translateY(-20vh) scale(0.49); }
    69.56522% {
      -webkit-transform: translateX(9vw) translateY(-2vh) scale(0.5);
              transform: translateX(9vw) translateY(-2vh) scale(0.5); }
    73.91304% {
      -webkit-transform: translateX(-41vw) translateY(39vh) scale(0.57);
              transform: translateX(-41vw) translateY(39vh) scale(0.57); }
    78.26087% {
      -webkit-transform: translateX(11vw) translateY(33vh) scale(0.26);
              transform: translateX(11vw) translateY(33vh) scale(0.26); }
    82.6087% {
      -webkit-transform: translateX(-31vw) translateY(43vh) scale(0.89);
              transform: translateX(-31vw) translateY(43vh) scale(0.89); }
    86.95652% {
      -webkit-transform: translateX(3vw) translateY(46vh) scale(0.45);
              transform: translateX(3vw) translateY(46vh) scale(0.45); }
    91.30435% {
      -webkit-transform: translateX(14vw) translateY(24vh) scale(0.43);
              transform: translateX(14vw) translateY(24vh) scale(0.43); }
    95.65217% {
      -webkit-transform: translateX(-8vw) translateY(-28vh) scale(0.41);
              transform: translateX(-8vw) translateY(-28vh) scale(0.41); }
    100% {
      -webkit-transform: translateX(4vw) translateY(-42vh) scale(0.41);
              transform: translateX(4vw) translateY(-42vh) scale(0.41); } }
  
  .firefly:nth-child(8) {
    -webkit-animation-name: move8;
            animation-name: move8; }
    .firefly:nth-child(8)::before {
      -webkit-animation-duration: 9s;
              animation-duration: 9s; }
    .firefly:nth-child(8)::after {
      -webkit-animation-duration: 9s, 9069ms;
              animation-duration: 9s, 9069ms;
      -webkit-animation-delay: 0ms, 5109ms;
              animation-delay: 0ms, 5109ms; }
  
  @-webkit-keyframes move8 {
    0% {
      -webkit-transform: translateX(11vw) translateY(-27vh) scale(0.31);
              transform: translateX(11vw) translateY(-27vh) scale(0.31); }
    5.26316% {
      -webkit-transform: translateX(15vw) translateY(29vh) scale(0.74);
              transform: translateX(15vw) translateY(29vh) scale(0.74); }
    10.52632% {
      -webkit-transform: translateX(-46vw) translateY(-7vh) scale(0.37);
              transform: translateX(-46vw) translateY(-7vh) scale(0.37); }
    15.78947% {
      -webkit-transform: translateX(-12vw) translateY(28vh) scale(0.79);
              transform: translateX(-12vw) translateY(28vh) scale(0.79); }
    21.05263% {
      -webkit-transform: translateX(-2vw) translateY(-7vh) scale(0.55);
              transform: translateX(-2vw) translateY(-7vh) scale(0.55); }
    26.31579% {
      -webkit-transform: translateX(46vw) translateY(21vh) scale(0.47);
              transform: translateX(46vw) translateY(21vh) scale(0.47); }
    31.57895% {
      -webkit-transform: translateX(-24vw) translateY(-2vh) scale(0.26);
              transform: translateX(-24vw) translateY(-2vh) scale(0.26); }
    36.84211% {
      -webkit-transform: translateX(29vw) translateY(34vh) scale(0.36);
              transform: translateX(29vw) translateY(34vh) scale(0.36); }
    42.10526% {
      -webkit-transform: translateX(-2vw) translateY(-2vh) scale(0.31);
              transform: translateX(-2vw) translateY(-2vh) scale(0.31); }
    47.36842% {
      -webkit-transform: translateX(15vw) translateY(11vh) scale(0.93);
              transform: translateX(15vw) translateY(11vh) scale(0.93); }
    52.63158% {
      -webkit-transform: translateX(-14vw) translateY(-11vh) scale(0.46);
              transform: translateX(-14vw) translateY(-11vh) scale(0.46); }
    57.89474% {
      -webkit-transform: translateX(-42vw) translateY(-17vh) scale(0.59);
              transform: translateX(-42vw) translateY(-17vh) scale(0.59); }
    63.15789% {
      -webkit-transform: translateX(4vw) translateY(-34vh) scale(0.79);
              transform: translateX(4vw) translateY(-34vh) scale(0.79); }
    68.42105% {
      -webkit-transform: translateX(-36vw) translateY(38vh) scale(0.5);
              transform: translateX(-36vw) translateY(38vh) scale(0.5); }
    73.68421% {
      -webkit-transform: translateX(20vw) translateY(-4vh) scale(0.57);
              transform: translateX(20vw) translateY(-4vh) scale(0.57); }
    78.94737% {
      -webkit-transform: translateX(-29vw) translateY(-21vh) scale(0.61);
              transform: translateX(-29vw) translateY(-21vh) scale(0.61); }
    84.21053% {
      -webkit-transform: translateX(-37vw) translateY(-47vh) scale(0.75);
              transform: translateX(-37vw) translateY(-47vh) scale(0.75); }
    89.47368% {
      -webkit-transform: translateX(43vw) translateY(28vh) scale(0.58);
              transform: translateX(43vw) translateY(28vh) scale(0.58); }
    94.73684% {
      -webkit-transform: translateX(37vw) translateY(24vh) scale(0.56);
              transform: translateX(37vw) translateY(24vh) scale(0.56); }
    100% {
      -webkit-transform: translateX(22vw) translateY(4vh) scale(0.92);
              transform: translateX(22vw) translateY(4vh) scale(0.92); } }
  
  @keyframes move8 {
    0% {
      -webkit-transform: translateX(11vw) translateY(-27vh) scale(0.31);
              transform: translateX(11vw) translateY(-27vh) scale(0.31); }
    5.26316% {
      -webkit-transform: translateX(15vw) translateY(29vh) scale(0.74);
              transform: translateX(15vw) translateY(29vh) scale(0.74); }
    10.52632% {
      -webkit-transform: translateX(-46vw) translateY(-7vh) scale(0.37);
              transform: translateX(-46vw) translateY(-7vh) scale(0.37); }
    15.78947% {
      -webkit-transform: translateX(-12vw) translateY(28vh) scale(0.79);
              transform: translateX(-12vw) translateY(28vh) scale(0.79); }
    21.05263% {
      -webkit-transform: translateX(-2vw) translateY(-7vh) scale(0.55);
              transform: translateX(-2vw) translateY(-7vh) scale(0.55); }
    26.31579% {
      -webkit-transform: translateX(46vw) translateY(21vh) scale(0.47);
              transform: translateX(46vw) translateY(21vh) scale(0.47); }
    31.57895% {
      -webkit-transform: translateX(-24vw) translateY(-2vh) scale(0.26);
              transform: translateX(-24vw) translateY(-2vh) scale(0.26); }
    36.84211% {
      -webkit-transform: translateX(29vw) translateY(34vh) scale(0.36);
              transform: translateX(29vw) translateY(34vh) scale(0.36); }
    42.10526% {
      -webkit-transform: translateX(-2vw) translateY(-2vh) scale(0.31);
              transform: translateX(-2vw) translateY(-2vh) scale(0.31); }
    47.36842% {
      -webkit-transform: translateX(15vw) translateY(11vh) scale(0.93);
              transform: translateX(15vw) translateY(11vh) scale(0.93); }
    52.63158% {
      -webkit-transform: translateX(-14vw) translateY(-11vh) scale(0.46);
              transform: translateX(-14vw) translateY(-11vh) scale(0.46); }
    57.89474% {
      -webkit-transform: translateX(-42vw) translateY(-17vh) scale(0.59);
              transform: translateX(-42vw) translateY(-17vh) scale(0.59); }
    63.15789% {
      -webkit-transform: translateX(4vw) translateY(-34vh) scale(0.79);
              transform: translateX(4vw) translateY(-34vh) scale(0.79); }
    68.42105% {
      -webkit-transform: translateX(-36vw) translateY(38vh) scale(0.5);
              transform: translateX(-36vw) translateY(38vh) scale(0.5); }
    73.68421% {
      -webkit-transform: translateX(20vw) translateY(-4vh) scale(0.57);
              transform: translateX(20vw) translateY(-4vh) scale(0.57); }
    78.94737% {
      -webkit-transform: translateX(-29vw) translateY(-21vh) scale(0.61);
              transform: translateX(-29vw) translateY(-21vh) scale(0.61); }
    84.21053% {
      -webkit-transform: translateX(-37vw) translateY(-47vh) scale(0.75);
              transform: translateX(-37vw) translateY(-47vh) scale(0.75); }
    89.47368% {
      -webkit-transform: translateX(43vw) translateY(28vh) scale(0.58);
              transform: translateX(43vw) translateY(28vh) scale(0.58); }
    94.73684% {
      -webkit-transform: translateX(37vw) translateY(24vh) scale(0.56);
              transform: translateX(37vw) translateY(24vh) scale(0.56); }
    100% {
      -webkit-transform: translateX(22vw) translateY(4vh) scale(0.92);
              transform: translateX(22vw) translateY(4vh) scale(0.92); } }
  
  .firefly:nth-child(9) {
    -webkit-animation-name: move9;
            animation-name: move9; }
    .firefly:nth-child(9)::before {
      -webkit-animation-duration: 9s;
              animation-duration: 9s; }
    .firefly:nth-child(9)::after {
      -webkit-animation-duration: 9s, 5428ms;
              animation-duration: 9s, 5428ms;
      -webkit-animation-delay: 0ms, 1738ms;
              animation-delay: 0ms, 1738ms; }
  
  @-webkit-keyframes move9 {
    0% {
      -webkit-transform: translateX(-42vw) translateY(-15vh) scale(0.76);
              transform: translateX(-42vw) translateY(-15vh) scale(0.76); }
    4.16667% {
      -webkit-transform: translateX(-31vw) translateY(-34vh) scale(0.45);
              transform: translateX(-31vw) translateY(-34vh) scale(0.45); }
    8.33333% {
      -webkit-transform: translateX(-14vw) translateY(-36vh) scale(0.89);
              transform: translateX(-14vw) translateY(-36vh) scale(0.89); }
    12.5% {
      -webkit-transform: translateX(-22vw) translateY(41vh) scale(0.53);
              transform: translateX(-22vw) translateY(41vh) scale(0.53); }
    16.66667% {
      -webkit-transform: translateX(-13vw) translateY(21vh) scale(0.98);
              transform: translateX(-13vw) translateY(21vh) scale(0.98); }
    20.83333% {
      -webkit-transform: translateX(-1vw) translateY(33vh) scale(0.37);
              transform: translateX(-1vw) translateY(33vh) scale(0.37); }
    25% {
      -webkit-transform: translateX(-39vw) translateY(-11vh) scale(0.54);
              transform: translateX(-39vw) translateY(-11vh) scale(0.54); }
    29.16667% {
      -webkit-transform: translateX(28vw) translateY(-23vh) scale(0.84);
              transform: translateX(28vw) translateY(-23vh) scale(0.84); }
    33.33333% {
      -webkit-transform: translateX(27vw) translateY(39vh) scale(0.34);
              transform: translateX(27vw) translateY(39vh) scale(0.34); }
    37.5% {
      -webkit-transform: translateX(-31vw) translateY(-31vh) scale(0.79);
              transform: translateX(-31vw) translateY(-31vh) scale(0.79); }
    41.66667% {
      -webkit-transform: translateX(-49vw) translateY(-6vh) scale(0.92);
              transform: translateX(-49vw) translateY(-6vh) scale(0.92); }
    45.83333% {
      -webkit-transform: translateX(-28vw) translateY(-18vh) scale(0.74);
              transform: translateX(-28vw) translateY(-18vh) scale(0.74); }
    50% {
      -webkit-transform: translateX(-3vw) translateY(32vh) scale(0.36);
              transform: translateX(-3vw) translateY(32vh) scale(0.36); }
    54.16667% {
      -webkit-transform: translateX(-39vw) translateY(28vh) scale(0.3);
              transform: translateX(-39vw) translateY(28vh) scale(0.3); }
    58.33333% {
      -webkit-transform: translateX(-24vw) translateY(15vh) scale(0.89);
              transform: translateX(-24vw) translateY(15vh) scale(0.89); }
    62.5% {
      -webkit-transform: translateX(8vw) translateY(39vh) scale(0.33);
              transform: translateX(8vw) translateY(39vh) scale(0.33); }
    66.66667% {
      -webkit-transform: translateX(7vw) translateY(41vh) scale(0.78);
              transform: translateX(7vw) translateY(41vh) scale(0.78); }
    70.83333% {
      -webkit-transform: translateX(0vw) translateY(-12vh) scale(0.8);
              transform: translateX(0vw) translateY(-12vh) scale(0.8); }
    75% {
      -webkit-transform: translateX(-5vw) translateY(46vh) scale(0.54);
              transform: translateX(-5vw) translateY(46vh) scale(0.54); }
    79.16667% {
      -webkit-transform: translateX(-25vw) translateY(-24vh) scale(0.72);
              transform: translateX(-25vw) translateY(-24vh) scale(0.72); }
    83.33333% {
      -webkit-transform: translateX(19vw) translateY(-44vh) scale(0.41);
              transform: translateX(19vw) translateY(-44vh) scale(0.41); }
    87.5% {
      -webkit-transform: translateX(-8vw) translateY(48vh) scale(0.53);
              transform: translateX(-8vw) translateY(48vh) scale(0.53); }
    91.66667% {
      -webkit-transform: translateX(3vw) translateY(22vh) scale(0.66);
              transform: translateX(3vw) translateY(22vh) scale(0.66); }
    95.83333% {
      -webkit-transform: translateX(-33vw) translateY(-24vh) scale(0.56);
              transform: translateX(-33vw) translateY(-24vh) scale(0.56); }
    100% {
      -webkit-transform: translateX(45vw) translateY(-14vh) scale(0.4);
              transform: translateX(45vw) translateY(-14vh) scale(0.4); } }
  
  @keyframes move9 {
    0% {
      -webkit-transform: translateX(-42vw) translateY(-15vh) scale(0.76);
              transform: translateX(-42vw) translateY(-15vh) scale(0.76); }
    4.16667% {
      -webkit-transform: translateX(-31vw) translateY(-34vh) scale(0.45);
              transform: translateX(-31vw) translateY(-34vh) scale(0.45); }
    8.33333% {
      -webkit-transform: translateX(-14vw) translateY(-36vh) scale(0.89);
              transform: translateX(-14vw) translateY(-36vh) scale(0.89); }
    12.5% {
      -webkit-transform: translateX(-22vw) translateY(41vh) scale(0.53);
              transform: translateX(-22vw) translateY(41vh) scale(0.53); }
    16.66667% {
      -webkit-transform: translateX(-13vw) translateY(21vh) scale(0.98);
              transform: translateX(-13vw) translateY(21vh) scale(0.98); }
    20.83333% {
      -webkit-transform: translateX(-1vw) translateY(33vh) scale(0.37);
              transform: translateX(-1vw) translateY(33vh) scale(0.37); }
    25% {
      -webkit-transform: translateX(-39vw) translateY(-11vh) scale(0.54);
              transform: translateX(-39vw) translateY(-11vh) scale(0.54); }
    29.16667% {
      -webkit-transform: translateX(28vw) translateY(-23vh) scale(0.84);
              transform: translateX(28vw) translateY(-23vh) scale(0.84); }
    33.33333% {
      -webkit-transform: translateX(27vw) translateY(39vh) scale(0.34);
              transform: translateX(27vw) translateY(39vh) scale(0.34); }
    37.5% {
      -webkit-transform: translateX(-31vw) translateY(-31vh) scale(0.79);
              transform: translateX(-31vw) translateY(-31vh) scale(0.79); }
    41.66667% {
      -webkit-transform: translateX(-49vw) translateY(-6vh) scale(0.92);
              transform: translateX(-49vw) translateY(-6vh) scale(0.92); }
    45.83333% {
      -webkit-transform: translateX(-28vw) translateY(-18vh) scale(0.74);
              transform: translateX(-28vw) translateY(-18vh) scale(0.74); }
    50% {
      -webkit-transform: translateX(-3vw) translateY(32vh) scale(0.36);
              transform: translateX(-3vw) translateY(32vh) scale(0.36); }
    54.16667% {
      -webkit-transform: translateX(-39vw) translateY(28vh) scale(0.3);
              transform: translateX(-39vw) translateY(28vh) scale(0.3); }
    58.33333% {
      -webkit-transform: translateX(-24vw) translateY(15vh) scale(0.89);
              transform: translateX(-24vw) translateY(15vh) scale(0.89); }
    62.5% {
      -webkit-transform: translateX(8vw) translateY(39vh) scale(0.33);
              transform: translateX(8vw) translateY(39vh) scale(0.33); }
    66.66667% {
      -webkit-transform: translateX(7vw) translateY(41vh) scale(0.78);
              transform: translateX(7vw) translateY(41vh) scale(0.78); }
    70.83333% {
      -webkit-transform: translateX(0vw) translateY(-12vh) scale(0.8);
              transform: translateX(0vw) translateY(-12vh) scale(0.8); }
    75% {
      -webkit-transform: translateX(-5vw) translateY(46vh) scale(0.54);
              transform: translateX(-5vw) translateY(46vh) scale(0.54); }
    79.16667% {
      -webkit-transform: translateX(-25vw) translateY(-24vh) scale(0.72);
              transform: translateX(-25vw) translateY(-24vh) scale(0.72); }
    83.33333% {
      -webkit-transform: translateX(19vw) translateY(-44vh) scale(0.41);
              transform: translateX(19vw) translateY(-44vh) scale(0.41); }
    87.5% {
      -webkit-transform: translateX(-8vw) translateY(48vh) scale(0.53);
              transform: translateX(-8vw) translateY(48vh) scale(0.53); }
    91.66667% {
      -webkit-transform: translateX(3vw) translateY(22vh) scale(0.66);
              transform: translateX(3vw) translateY(22vh) scale(0.66); }
    95.83333% {
      -webkit-transform: translateX(-33vw) translateY(-24vh) scale(0.56);
              transform: translateX(-33vw) translateY(-24vh) scale(0.56); }
    100% {
      -webkit-transform: translateX(45vw) translateY(-14vh) scale(0.4);
              transform: translateX(45vw) translateY(-14vh) scale(0.4); } }
  
  .firefly:nth-child(10) {
    -webkit-animation-name: move10;
            animation-name: move10; }
    .firefly:nth-child(10)::before {
      -webkit-animation-duration: 18s;
              animation-duration: 18s; }
    .firefly:nth-child(10)::after {
      -webkit-animation-duration: 18s, 6491ms;
              animation-duration: 18s, 6491ms;
      -webkit-animation-delay: 0ms, 565ms;
              animation-delay: 0ms, 565ms; }
  
  @-webkit-keyframes move10 {
    0% {
      -webkit-transform: translateX(-9vw) translateY(3vh) scale(0.86);
              transform: translateX(-9vw) translateY(3vh) scale(0.86); }
    4.7619% {
      -webkit-transform: translateX(-37vw) translateY(-27vh) scale(0.9);
              transform: translateX(-37vw) translateY(-27vh) scale(0.9); }
    9.52381% {
      -webkit-transform: translateX(-46vw) translateY(38vh) scale(0.45);
              transform: translateX(-46vw) translateY(38vh) scale(0.45); }
    14.28571% {
      -webkit-transform: translateX(44vw) translateY(26vh) scale(0.91);
              transform: translateX(44vw) translateY(26vh) scale(0.91); }
    19.04762% {
      -webkit-transform: translateX(-6vw) translateY(28vh) scale(0.26);
              transform: translateX(-6vw) translateY(28vh) scale(0.26); }
    23.80952% {
      -webkit-transform: translateX(27vw) translateY(-39vh) scale(0.88);
              transform: translateX(27vw) translateY(-39vh) scale(0.88); }
    28.57143% {
      -webkit-transform: translateX(-39vw) translateY(31vh) scale(0.99);
              transform: translateX(-39vw) translateY(31vh) scale(0.99); }
    33.33333% {
      -webkit-transform: translateX(-41vw) translateY(-19vh) scale(1);
              transform: translateX(-41vw) translateY(-19vh) scale(1); }
    38.09524% {
      -webkit-transform: translateX(29vw) translateY(47vh) scale(0.34);
              transform: translateX(29vw) translateY(47vh) scale(0.34); }
    42.85714% {
      -webkit-transform: translateX(-34vw) translateY(6vh) scale(0.55);
              transform: translateX(-34vw) translateY(6vh) scale(0.55); }
    47.61905% {
      -webkit-transform: translateX(45vw) translateY(-42vh) scale(0.36);
              transform: translateX(45vw) translateY(-42vh) scale(0.36); }
    52.38095% {
      -webkit-transform: translateX(45vw) translateY(-46vh) scale(0.37);
              transform: translateX(45vw) translateY(-46vh) scale(0.37); }
    57.14286% {
      -webkit-transform: translateX(-7vw) translateY(-14vh) scale(0.36);
              transform: translateX(-7vw) translateY(-14vh) scale(0.36); }
    61.90476% {
      -webkit-transform: translateX(38vw) translateY(3vh) scale(0.42);
              transform: translateX(38vw) translateY(3vh) scale(0.42); }
    66.66667% {
      -webkit-transform: translateX(38vw) translateY(-15vh) scale(0.38);
              transform: translateX(38vw) translateY(-15vh) scale(0.38); }
    71.42857% {
      -webkit-transform: translateX(15vw) translateY(4vh) scale(0.34);
              transform: translateX(15vw) translateY(4vh) scale(0.34); }
    76.19048% {
      -webkit-transform: translateX(34vw) translateY(-20vh) scale(0.97);
              transform: translateX(34vw) translateY(-20vh) scale(0.97); }
    80.95238% {
      -webkit-transform: translateX(1vw) translateY(-42vh) scale(0.85);
              transform: translateX(1vw) translateY(-42vh) scale(0.85); }
    85.71429% {
      -webkit-transform: translateX(44vw) translateY(-11vh) scale(0.47);
              transform: translateX(44vw) translateY(-11vh) scale(0.47); }
    90.47619% {
      -webkit-transform: translateX(-44vw) translateY(-20vh) scale(0.93);
              transform: translateX(-44vw) translateY(-20vh) scale(0.93); }
    95.2381% {
      -webkit-transform: translateX(48vw) translateY(12vh) scale(0.36);
              transform: translateX(48vw) translateY(12vh) scale(0.36); }
    100% {
      -webkit-transform: translateX(-14vw) translateY(41vh) scale(0.73);
              transform: translateX(-14vw) translateY(41vh) scale(0.73); } }
  
  @keyframes move10 {
    0% {
      -webkit-transform: translateX(-9vw) translateY(3vh) scale(0.86);
              transform: translateX(-9vw) translateY(3vh) scale(0.86); }
    4.7619% {
      -webkit-transform: translateX(-37vw) translateY(-27vh) scale(0.9);
              transform: translateX(-37vw) translateY(-27vh) scale(0.9); }
    9.52381% {
      -webkit-transform: translateX(-46vw) translateY(38vh) scale(0.45);
              transform: translateX(-46vw) translateY(38vh) scale(0.45); }
    14.28571% {
      -webkit-transform: translateX(44vw) translateY(26vh) scale(0.91);
              transform: translateX(44vw) translateY(26vh) scale(0.91); }
    19.04762% {
      -webkit-transform: translateX(-6vw) translateY(28vh) scale(0.26);
              transform: translateX(-6vw) translateY(28vh) scale(0.26); }
    23.80952% {
      -webkit-transform: translateX(27vw) translateY(-39vh) scale(0.88);
              transform: translateX(27vw) translateY(-39vh) scale(0.88); }
    28.57143% {
      -webkit-transform: translateX(-39vw) translateY(31vh) scale(0.99);
              transform: translateX(-39vw) translateY(31vh) scale(0.99); }
    33.33333% {
      -webkit-transform: translateX(-41vw) translateY(-19vh) scale(1);
              transform: translateX(-41vw) translateY(-19vh) scale(1); }
    38.09524% {
      -webkit-transform: translateX(29vw) translateY(47vh) scale(0.34);
              transform: translateX(29vw) translateY(47vh) scale(0.34); }
    42.85714% {
      -webkit-transform: translateX(-34vw) translateY(6vh) scale(0.55);
              transform: translateX(-34vw) translateY(6vh) scale(0.55); }
    47.61905% {
      -webkit-transform: translateX(45vw) translateY(-42vh) scale(0.36);
              transform: translateX(45vw) translateY(-42vh) scale(0.36); }
    52.38095% {
      -webkit-transform: translateX(45vw) translateY(-46vh) scale(0.37);
              transform: translateX(45vw) translateY(-46vh) scale(0.37); }
    57.14286% {
      -webkit-transform: translateX(-7vw) translateY(-14vh) scale(0.36);
              transform: translateX(-7vw) translateY(-14vh) scale(0.36); }
    61.90476% {
      -webkit-transform: translateX(38vw) translateY(3vh) scale(0.42);
              transform: translateX(38vw) translateY(3vh) scale(0.42); }
    66.66667% {
      -webkit-transform: translateX(38vw) translateY(-15vh) scale(0.38);
              transform: translateX(38vw) translateY(-15vh) scale(0.38); }
    71.42857% {
      -webkit-transform: translateX(15vw) translateY(4vh) scale(0.34);
              transform: translateX(15vw) translateY(4vh) scale(0.34); }
    76.19048% {
      -webkit-transform: translateX(34vw) translateY(-20vh) scale(0.97);
              transform: translateX(34vw) translateY(-20vh) scale(0.97); }
    80.95238% {
      -webkit-transform: translateX(1vw) translateY(-42vh) scale(0.85);
              transform: translateX(1vw) translateY(-42vh) scale(0.85); }
    85.71429% {
      -webkit-transform: translateX(44vw) translateY(-11vh) scale(0.47);
              transform: translateX(44vw) translateY(-11vh) scale(0.47); }
    90.47619% {
      -webkit-transform: translateX(-44vw) translateY(-20vh) scale(0.93);
              transform: translateX(-44vw) translateY(-20vh) scale(0.93); }
    95.2381% {
      -webkit-transform: translateX(48vw) translateY(12vh) scale(0.36);
              transform: translateX(48vw) translateY(12vh) scale(0.36); }
    100% {
      -webkit-transform: translateX(-14vw) translateY(41vh) scale(0.73);
              transform: translateX(-14vw) translateY(41vh) scale(0.73); } }
  
  .firefly:nth-child(11) {
    -webkit-animation-name: move11;
            animation-name: move11; }
    .firefly:nth-child(11)::before {
      -webkit-animation-duration: 17s;
              animation-duration: 17s; }
    .firefly:nth-child(11)::after {
      -webkit-animation-duration: 17s, 6970ms;
              animation-duration: 17s, 6970ms;
      -webkit-animation-delay: 0ms, 6990ms;
              animation-delay: 0ms, 6990ms; }
  
  @-webkit-keyframes move11 {
    0% {
      -webkit-transform: translateX(5vw) translateY(24vh) scale(0.39);
              transform: translateX(5vw) translateY(24vh) scale(0.39); }
    4.34783% {
      -webkit-transform: translateX(30vw) translateY(-15vh) scale(0.9);
              transform: translateX(30vw) translateY(-15vh) scale(0.9); }
    8.69565% {
      -webkit-transform: translateX(29vw) translateY(45vh) scale(0.74);
              transform: translateX(29vw) translateY(45vh) scale(0.74); }
    13.04348% {
      -webkit-transform: translateX(-21vw) translateY(30vh) scale(0.86);
              transform: translateX(-21vw) translateY(30vh) scale(0.86); }
    17.3913% {
      -webkit-transform: translateX(33vw) translateY(35vh) scale(0.9);
              transform: translateX(33vw) translateY(35vh) scale(0.9); }
    21.73913% {
      -webkit-transform: translateX(-9vw) translateY(-29vh) scale(0.34);
              transform: translateX(-9vw) translateY(-29vh) scale(0.34); }
    26.08696% {
      -webkit-transform: translateX(16vw) translateY(9vh) scale(0.88);
              transform: translateX(16vw) translateY(9vh) scale(0.88); }
    30.43478% {
      -webkit-transform: translateX(20vw) translateY(-36vh) scale(0.52);
              transform: translateX(20vw) translateY(-36vh) scale(0.52); }
    34.78261% {
      -webkit-transform: translateX(9vw) translateY(34vh) scale(0.63);
              transform: translateX(9vw) translateY(34vh) scale(0.63); }
    39.13043% {
      -webkit-transform: translateX(20vw) translateY(-14vh) scale(0.37);
              transform: translateX(20vw) translateY(-14vh) scale(0.37); }
    43.47826% {
      -webkit-transform: translateX(43vw) translateY(9vh) scale(0.94);
              transform: translateX(43vw) translateY(9vh) scale(0.94); }
    47.82609% {
      -webkit-transform: translateX(-2vw) translateY(29vh) scale(0.92);
              transform: translateX(-2vw) translateY(29vh) scale(0.92); }
    52.17391% {
      -webkit-transform: translateX(3vw) translateY(29vh) scale(0.53);
              transform: translateX(3vw) translateY(29vh) scale(0.53); }
    56.52174% {
      -webkit-transform: translateX(-7vw) translateY(27vh) scale(0.38);
              transform: translateX(-7vw) translateY(27vh) scale(0.38); }
    60.86957% {
      -webkit-transform: translateX(-34vw) translateY(46vh) scale(0.81);
              transform: translateX(-34vw) translateY(46vh) scale(0.81); }
    65.21739% {
      -webkit-transform: translateX(12vw) translateY(22vh) scale(0.53);
              transform: translateX(12vw) translateY(22vh) scale(0.53); }
    69.56522% {
      -webkit-transform: translateX(-24vw) translateY(16vh) scale(0.47);
              transform: translateX(-24vw) translateY(16vh) scale(0.47); }
    73.91304% {
      -webkit-transform: translateX(-31vw) translateY(37vh) scale(0.97);
              transform: translateX(-31vw) translateY(37vh) scale(0.97); }
    78.26087% {
      -webkit-transform: translateX(-35vw) translateY(30vh) scale(0.91);
              transform: translateX(-35vw) translateY(30vh) scale(0.91); }
    82.6087% {
      -webkit-transform: translateX(37vw) translateY(-48vh) scale(0.5);
              transform: translateX(37vw) translateY(-48vh) scale(0.5); }
    86.95652% {
      -webkit-transform: translateX(-29vw) translateY(-31vh) scale(0.34);
              transform: translateX(-29vw) translateY(-31vh) scale(0.34); }
    91.30435% {
      -webkit-transform: translateX(-33vw) translateY(33vh) scale(0.98);
              transform: translateX(-33vw) translateY(33vh) scale(0.98); }
    95.65217% {
      -webkit-transform: translateX(-24vw) translateY(-40vh) scale(0.81);
              transform: translateX(-24vw) translateY(-40vh) scale(0.81); }
    100% {
      -webkit-transform: translateX(-20vw) translateY(-15vh) scale(0.69);
              transform: translateX(-20vw) translateY(-15vh) scale(0.69); } }
  
  @keyframes move11 {
    0% {
      -webkit-transform: translateX(5vw) translateY(24vh) scale(0.39);
              transform: translateX(5vw) translateY(24vh) scale(0.39); }
    4.34783% {
      -webkit-transform: translateX(30vw) translateY(-15vh) scale(0.9);
              transform: translateX(30vw) translateY(-15vh) scale(0.9); }
    8.69565% {
      -webkit-transform: translateX(29vw) translateY(45vh) scale(0.74);
              transform: translateX(29vw) translateY(45vh) scale(0.74); }
    13.04348% {
      -webkit-transform: translateX(-21vw) translateY(30vh) scale(0.86);
              transform: translateX(-21vw) translateY(30vh) scale(0.86); }
    17.3913% {
      -webkit-transform: translateX(33vw) translateY(35vh) scale(0.9);
              transform: translateX(33vw) translateY(35vh) scale(0.9); }
    21.73913% {
      -webkit-transform: translateX(-9vw) translateY(-29vh) scale(0.34);
              transform: translateX(-9vw) translateY(-29vh) scale(0.34); }
    26.08696% {
      -webkit-transform: translateX(16vw) translateY(9vh) scale(0.88);
              transform: translateX(16vw) translateY(9vh) scale(0.88); }
    30.43478% {
      -webkit-transform: translateX(20vw) translateY(-36vh) scale(0.52);
              transform: translateX(20vw) translateY(-36vh) scale(0.52); }
    34.78261% {
      -webkit-transform: translateX(9vw) translateY(34vh) scale(0.63);
              transform: translateX(9vw) translateY(34vh) scale(0.63); }
    39.13043% {
      -webkit-transform: translateX(20vw) translateY(-14vh) scale(0.37);
              transform: translateX(20vw) translateY(-14vh) scale(0.37); }
    43.47826% {
      -webkit-transform: translateX(43vw) translateY(9vh) scale(0.94);
              transform: translateX(43vw) translateY(9vh) scale(0.94); }
    47.82609% {
      -webkit-transform: translateX(-2vw) translateY(29vh) scale(0.92);
              transform: translateX(-2vw) translateY(29vh) scale(0.92); }
    52.17391% {
      -webkit-transform: translateX(3vw) translateY(29vh) scale(0.53);
              transform: translateX(3vw) translateY(29vh) scale(0.53); }
    56.52174% {
      -webkit-transform: translateX(-7vw) translateY(27vh) scale(0.38);
              transform: translateX(-7vw) translateY(27vh) scale(0.38); }
    60.86957% {
      -webkit-transform: translateX(-34vw) translateY(46vh) scale(0.81);
              transform: translateX(-34vw) translateY(46vh) scale(0.81); }
    65.21739% {
      -webkit-transform: translateX(12vw) translateY(22vh) scale(0.53);
              transform: translateX(12vw) translateY(22vh) scale(0.53); }
    69.56522% {
      -webkit-transform: translateX(-24vw) translateY(16vh) scale(0.47);
              transform: translateX(-24vw) translateY(16vh) scale(0.47); }
    73.91304% {
      -webkit-transform: translateX(-31vw) translateY(37vh) scale(0.97);
              transform: translateX(-31vw) translateY(37vh) scale(0.97); }
    78.26087% {
      -webkit-transform: translateX(-35vw) translateY(30vh) scale(0.91);
              transform: translateX(-35vw) translateY(30vh) scale(0.91); }
    82.6087% {
      -webkit-transform: translateX(37vw) translateY(-48vh) scale(0.5);
              transform: translateX(37vw) translateY(-48vh) scale(0.5); }
    86.95652% {
      -webkit-transform: translateX(-29vw) translateY(-31vh) scale(0.34);
              transform: translateX(-29vw) translateY(-31vh) scale(0.34); }
    91.30435% {
      -webkit-transform: translateX(-33vw) translateY(33vh) scale(0.98);
              transform: translateX(-33vw) translateY(33vh) scale(0.98); }
    95.65217% {
      -webkit-transform: translateX(-24vw) translateY(-40vh) scale(0.81);
              transform: translateX(-24vw) translateY(-40vh) scale(0.81); }
    100% {
      -webkit-transform: translateX(-20vw) translateY(-15vh) scale(0.69);
              transform: translateX(-20vw) translateY(-15vh) scale(0.69); } }
  
  .firefly:nth-child(12) {
    -webkit-animation-name: move12;
            animation-name: move12; }
    .firefly:nth-child(12)::before {
      -webkit-animation-duration: 11s;
              animation-duration: 11s; }
    .firefly:nth-child(12)::after {
      -webkit-animation-duration: 11s, 8667ms;
              animation-duration: 11s, 8667ms;
      -webkit-animation-delay: 0ms, 1006ms;
              animation-delay: 0ms, 1006ms; }
  
  @-webkit-keyframes move12 {
    0% {
      -webkit-transform: translateX(-2vw) translateY(38vh) scale(0.3);
              transform: translateX(-2vw) translateY(38vh) scale(0.3); }
    5% {
      -webkit-transform: translateX(-42vw) translateY(-35vh) scale(0.54);
              transform: translateX(-42vw) translateY(-35vh) scale(0.54); }
    10% {
      -webkit-transform: translateX(-3vw) translateY(1vh) scale(0.89);
              transform: translateX(-3vw) translateY(1vh) scale(0.89); }
    15% {
      -webkit-transform: translateX(5vw) translateY(12vh) scale(0.26);
              transform: translateX(5vw) translateY(12vh) scale(0.26); }
    20% {
      -webkit-transform: translateX(-42vw) translateY(-16vh) scale(0.61);
              transform: translateX(-42vw) translateY(-16vh) scale(0.61); }
    25% {
      -webkit-transform: translateX(-2vw) translateY(-14vh) scale(0.64);
              transform: translateX(-2vw) translateY(-14vh) scale(0.64); }
    30% {
      -webkit-transform: translateX(-49vw) translateY(-9vh) scale(0.36);
              transform: translateX(-49vw) translateY(-9vh) scale(0.36); }
    35% {
      -webkit-transform: translateX(-27vw) translateY(1vh) scale(0.41);
              transform: translateX(-27vw) translateY(1vh) scale(0.41); }
    40% {
      -webkit-transform: translateX(-47vw) translateY(19vh) scale(0.84);
              transform: translateX(-47vw) translateY(19vh) scale(0.84); }
    45% {
      -webkit-transform: translateX(32vw) translateY(-22vh) scale(0.93);
              transform: translateX(32vw) translateY(-22vh) scale(0.93); }
    50% {
      -webkit-transform: translateX(24vw) translateY(-46vh) scale(0.78);
              transform: translateX(24vw) translateY(-46vh) scale(0.78); }
    55% {
      -webkit-transform: translateX(-35vw) translateY(42vh) scale(0.5);
              transform: translateX(-35vw) translateY(42vh) scale(0.5); }
    60% {
      -webkit-transform: translateX(4vw) translateY(-19vh) scale(0.55);
              transform: translateX(4vw) translateY(-19vh) scale(0.55); }
    65% {
      -webkit-transform: translateX(20vw) translateY(-10vh) scale(0.65);
              transform: translateX(20vw) translateY(-10vh) scale(0.65); }
    70% {
      -webkit-transform: translateX(5vw) translateY(18vh) scale(0.3);
              transform: translateX(5vw) translateY(18vh) scale(0.3); }
    75% {
      -webkit-transform: translateX(4vw) translateY(-40vh) scale(0.74);
              transform: translateX(4vw) translateY(-40vh) scale(0.74); }
    80% {
      -webkit-transform: translateX(-25vw) translateY(-16vh) scale(0.99);
              transform: translateX(-25vw) translateY(-16vh) scale(0.99); }
    85% {
      -webkit-transform: translateX(43vw) translateY(10vh) scale(0.87);
              transform: translateX(43vw) translateY(10vh) scale(0.87); }
    90% {
      -webkit-transform: translateX(-9vw) translateY(2vh) scale(0.3);
              transform: translateX(-9vw) translateY(2vh) scale(0.3); }
    95% {
      -webkit-transform: translateX(26vw) translateY(-15vh) scale(0.67);
              transform: translateX(26vw) translateY(-15vh) scale(0.67); }
    100% {
      -webkit-transform: translateX(46vw) translateY(-24vh) scale(0.43);
              transform: translateX(46vw) translateY(-24vh) scale(0.43); } }
  
  @keyframes move12 {
    0% {
      -webkit-transform: translateX(-2vw) translateY(38vh) scale(0.3);
              transform: translateX(-2vw) translateY(38vh) scale(0.3); }
    5% {
      -webkit-transform: translateX(-42vw) translateY(-35vh) scale(0.54);
              transform: translateX(-42vw) translateY(-35vh) scale(0.54); }
    10% {
      -webkit-transform: translateX(-3vw) translateY(1vh) scale(0.89);
              transform: translateX(-3vw) translateY(1vh) scale(0.89); }
    15% {
      -webkit-transform: translateX(5vw) translateY(12vh) scale(0.26);
              transform: translateX(5vw) translateY(12vh) scale(0.26); }
    20% {
      -webkit-transform: translateX(-42vw) translateY(-16vh) scale(0.61);
              transform: translateX(-42vw) translateY(-16vh) scale(0.61); }
    25% {
      -webkit-transform: translateX(-2vw) translateY(-14vh) scale(0.64);
              transform: translateX(-2vw) translateY(-14vh) scale(0.64); }
    30% {
      -webkit-transform: translateX(-49vw) translateY(-9vh) scale(0.36);
              transform: translateX(-49vw) translateY(-9vh) scale(0.36); }
    35% {
      -webkit-transform: translateX(-27vw) translateY(1vh) scale(0.41);
              transform: translateX(-27vw) translateY(1vh) scale(0.41); }
    40% {
      -webkit-transform: translateX(-47vw) translateY(19vh) scale(0.84);
              transform: translateX(-47vw) translateY(19vh) scale(0.84); }
    45% {
      -webkit-transform: translateX(32vw) translateY(-22vh) scale(0.93);
              transform: translateX(32vw) translateY(-22vh) scale(0.93); }
    50% {
      -webkit-transform: translateX(24vw) translateY(-46vh) scale(0.78);
              transform: translateX(24vw) translateY(-46vh) scale(0.78); }
    55% {
      -webkit-transform: translateX(-35vw) translateY(42vh) scale(0.5);
              transform: translateX(-35vw) translateY(42vh) scale(0.5); }
    60% {
      -webkit-transform: translateX(4vw) translateY(-19vh) scale(0.55);
              transform: translateX(4vw) translateY(-19vh) scale(0.55); }
    65% {
      -webkit-transform: translateX(20vw) translateY(-10vh) scale(0.65);
              transform: translateX(20vw) translateY(-10vh) scale(0.65); }
    70% {
      -webkit-transform: translateX(5vw) translateY(18vh) scale(0.3);
              transform: translateX(5vw) translateY(18vh) scale(0.3); }
    75% {
      -webkit-transform: translateX(4vw) translateY(-40vh) scale(0.74);
              transform: translateX(4vw) translateY(-40vh) scale(0.74); }
    80% {
      -webkit-transform: translateX(-25vw) translateY(-16vh) scale(0.99);
              transform: translateX(-25vw) translateY(-16vh) scale(0.99); }
    85% {
      -webkit-transform: translateX(43vw) translateY(10vh) scale(0.87);
              transform: translateX(43vw) translateY(10vh) scale(0.87); }
    90% {
      -webkit-transform: translateX(-9vw) translateY(2vh) scale(0.3);
              transform: translateX(-9vw) translateY(2vh) scale(0.3); }
    95% {
      -webkit-transform: translateX(26vw) translateY(-15vh) scale(0.67);
              transform: translateX(26vw) translateY(-15vh) scale(0.67); }
    100% {
      -webkit-transform: translateX(46vw) translateY(-24vh) scale(0.43);
              transform: translateX(46vw) translateY(-24vh) scale(0.43); } }
  
  .firefly:nth-child(13) {
    -webkit-animation-name: move13;
            animation-name: move13; }
    .firefly:nth-child(13)::before {
      -webkit-animation-duration: 9s;
              animation-duration: 9s; }
    .firefly:nth-child(13)::after {
      -webkit-animation-duration: 9s, 6366ms;
              animation-duration: 9s, 6366ms;
      -webkit-animation-delay: 0ms, 1647ms;
              animation-delay: 0ms, 1647ms; }
  
  @-webkit-keyframes move13 {
    0% {
      -webkit-transform: translateX(-27vw) translateY(22vh) scale(0.9);
              transform: translateX(-27vw) translateY(22vh) scale(0.9); }
    5.88235% {
      -webkit-transform: translateX(-8vw) translateY(-7vh) scale(0.33);
              transform: translateX(-8vw) translateY(-7vh) scale(0.33); }
    11.76471% {
      -webkit-transform: translateX(36vw) translateY(8vh) scale(0.87);
              transform: translateX(36vw) translateY(8vh) scale(0.87); }
    17.64706% {
      -webkit-transform: translateX(19vw) translateY(-35vh) scale(0.99);
              transform: translateX(19vw) translateY(-35vh) scale(0.99); }
    23.52941% {
      -webkit-transform: translateX(42vw) translateY(-46vh) scale(0.84);
              transform: translateX(42vw) translateY(-46vh) scale(0.84); }
    29.41176% {
      -webkit-transform: translateX(-32vw) translateY(-48vh) scale(0.31);
              transform: translateX(-32vw) translateY(-48vh) scale(0.31); }
    35.29412% {
      -webkit-transform: translateX(-31vw) translateY(28vh) scale(0.63);
              transform: translateX(-31vw) translateY(28vh) scale(0.63); }
    41.17647% {
      -webkit-transform: translateX(-21vw) translateY(-4vh) scale(0.63);
              transform: translateX(-21vw) translateY(-4vh) scale(0.63); }
    47.05882% {
      -webkit-transform: translateX(-27vw) translateY(-12vh) scale(0.88);
              transform: translateX(-27vw) translateY(-12vh) scale(0.88); }
    52.94118% {
      -webkit-transform: translateX(-49vw) translateY(-47vh) scale(0.74);
              transform: translateX(-49vw) translateY(-47vh) scale(0.74); }
    58.82353% {
      -webkit-transform: translateX(3vw) translateY(23vh) scale(0.97);
              transform: translateX(3vw) translateY(23vh) scale(0.97); }
    64.70588% {
      -webkit-transform: translateX(-20vw) translateY(44vh) scale(0.82);
              transform: translateX(-20vw) translateY(44vh) scale(0.82); }
    70.58824% {
      -webkit-transform: translateX(30vw) translateY(47vh) scale(0.33);
              transform: translateX(30vw) translateY(47vh) scale(0.33); }
    76.47059% {
      -webkit-transform: translateX(29vw) translateY(-5vh) scale(0.31);
              transform: translateX(29vw) translateY(-5vh) scale(0.31); }
    82.35294% {
      -webkit-transform: translateX(-9vw) translateY(-1vh) scale(0.92);
              transform: translateX(-9vw) translateY(-1vh) scale(0.92); }
    88.23529% {
      -webkit-transform: translateX(34vw) translateY(47vh) scale(0.86);
              transform: translateX(34vw) translateY(47vh) scale(0.86); }
    94.11765% {
      -webkit-transform: translateX(-12vw) translateY(12vh) scale(0.47);
              transform: translateX(-12vw) translateY(12vh) scale(0.47); }
    100% {
      -webkit-transform: translateX(10vw) translateY(-4vh) scale(0.81);
              transform: translateX(10vw) translateY(-4vh) scale(0.81); } }
  
  @keyframes move13 {
    0% {
      -webkit-transform: translateX(-27vw) translateY(22vh) scale(0.9);
              transform: translateX(-27vw) translateY(22vh) scale(0.9); }
    5.88235% {
      -webkit-transform: translateX(-8vw) translateY(-7vh) scale(0.33);
              transform: translateX(-8vw) translateY(-7vh) scale(0.33); }
    11.76471% {
      -webkit-transform: translateX(36vw) translateY(8vh) scale(0.87);
              transform: translateX(36vw) translateY(8vh) scale(0.87); }
    17.64706% {
      -webkit-transform: translateX(19vw) translateY(-35vh) scale(0.99);
              transform: translateX(19vw) translateY(-35vh) scale(0.99); }
    23.52941% {
      -webkit-transform: translateX(42vw) translateY(-46vh) scale(0.84);
              transform: translateX(42vw) translateY(-46vh) scale(0.84); }
    29.41176% {
      -webkit-transform: translateX(-32vw) translateY(-48vh) scale(0.31);
              transform: translateX(-32vw) translateY(-48vh) scale(0.31); }
    35.29412% {
      -webkit-transform: translateX(-31vw) translateY(28vh) scale(0.63);
              transform: translateX(-31vw) translateY(28vh) scale(0.63); }
    41.17647% {
      -webkit-transform: translateX(-21vw) translateY(-4vh) scale(0.63);
              transform: translateX(-21vw) translateY(-4vh) scale(0.63); }
    47.05882% {
      -webkit-transform: translateX(-27vw) translateY(-12vh) scale(0.88);
              transform: translateX(-27vw) translateY(-12vh) scale(0.88); }
    52.94118% {
      -webkit-transform: translateX(-49vw) translateY(-47vh) scale(0.74);
              transform: translateX(-49vw) translateY(-47vh) scale(0.74); }
    58.82353% {
      -webkit-transform: translateX(3vw) translateY(23vh) scale(0.97);
              transform: translateX(3vw) translateY(23vh) scale(0.97); }
    64.70588% {
      -webkit-transform: translateX(-20vw) translateY(44vh) scale(0.82);
              transform: translateX(-20vw) translateY(44vh) scale(0.82); }
    70.58824% {
      -webkit-transform: translateX(30vw) translateY(47vh) scale(0.33);
              transform: translateX(30vw) translateY(47vh) scale(0.33); }
    76.47059% {
      -webkit-transform: translateX(29vw) translateY(-5vh) scale(0.31);
              transform: translateX(29vw) translateY(-5vh) scale(0.31); }
    82.35294% {
      -webkit-transform: translateX(-9vw) translateY(-1vh) scale(0.92);
              transform: translateX(-9vw) translateY(-1vh) scale(0.92); }
    88.23529% {
      -webkit-transform: translateX(34vw) translateY(47vh) scale(0.86);
              transform: translateX(34vw) translateY(47vh) scale(0.86); }
    94.11765% {
      -webkit-transform: translateX(-12vw) translateY(12vh) scale(0.47);
              transform: translateX(-12vw) translateY(12vh) scale(0.47); }
    100% {
      -webkit-transform: translateX(10vw) translateY(-4vh) scale(0.81);
              transform: translateX(10vw) translateY(-4vh) scale(0.81); } }
  
  .firefly:nth-child(14) {
    -webkit-animation-name: move14;
            animation-name: move14; }
    .firefly:nth-child(14)::before {
      -webkit-animation-duration: 17s;
              animation-duration: 17s; }
    .firefly:nth-child(14)::after {
      -webkit-animation-duration: 17s, 10125ms;
              animation-duration: 17s, 10125ms;
      -webkit-animation-delay: 0ms, 2851ms;
              animation-delay: 0ms, 2851ms; }
  
  @-webkit-keyframes move14 {
    0% {
      -webkit-transform: translateX(-48vw) translateY(7vh) scale(0.42);
              transform: translateX(-48vw) translateY(7vh) scale(0.42); }
    3.84615% {
      -webkit-transform: translateX(9vw) translateY(-16vh) scale(0.88);
              transform: translateX(9vw) translateY(-16vh) scale(0.88); }
    7.69231% {
      -webkit-transform: translateX(-31vw) translateY(-1vh) scale(1);
              transform: translateX(-31vw) translateY(-1vh) scale(1); }
    11.53846% {
      -webkit-transform: translateX(-38vw) translateY(-23vh) scale(0.8);
              transform: translateX(-38vw) translateY(-23vh) scale(0.8); }
    15.38462% {
      -webkit-transform: translateX(8vw) translateY(32vh) scale(0.61);
              transform: translateX(8vw) translateY(32vh) scale(0.61); }
    19.23077% {
      -webkit-transform: translateX(-42vw) translateY(-40vh) scale(0.6);
              transform: translateX(-42vw) translateY(-40vh) scale(0.6); }
    23.07692% {
      -webkit-transform: translateX(-31vw) translateY(20vh) scale(0.69);
              transform: translateX(-31vw) translateY(20vh) scale(0.69); }
    26.92308% {
      -webkit-transform: translateX(-40vw) translateY(44vh) scale(0.5);
              transform: translateX(-40vw) translateY(44vh) scale(0.5); }
    30.76923% {
      -webkit-transform: translateX(-40vw) translateY(-9vh) scale(0.37);
              transform: translateX(-40vw) translateY(-9vh) scale(0.37); }
    34.61538% {
      -webkit-transform: translateX(2vw) translateY(-29vh) scale(0.6);
              transform: translateX(2vw) translateY(-29vh) scale(0.6); }
    38.46154% {
      -webkit-transform: translateX(-24vw) translateY(16vh) scale(0.74);
              transform: translateX(-24vw) translateY(16vh) scale(0.74); }
    42.30769% {
      -webkit-transform: translateX(-15vw) translateY(-30vh) scale(0.5);
              transform: translateX(-15vw) translateY(-30vh) scale(0.5); }
    46.15385% {
      -webkit-transform: translateX(2vw) translateY(4vh) scale(0.47);
              transform: translateX(2vw) translateY(4vh) scale(0.47); }
    50% {
      -webkit-transform: translateX(-40vw) translateY(9vh) scale(0.85);
              transform: translateX(-40vw) translateY(9vh) scale(0.85); }
    53.84615% {
      -webkit-transform: translateX(-5vw) translateY(23vh) scale(0.46);
              transform: translateX(-5vw) translateY(23vh) scale(0.46); }
    57.69231% {
      -webkit-transform: translateX(-4vw) translateY(7vh) scale(0.86);
              transform: translateX(-4vw) translateY(7vh) scale(0.86); }
    61.53846% {
      -webkit-transform: translateX(18vw) translateY(-23vh) scale(0.64);
              transform: translateX(18vw) translateY(-23vh) scale(0.64); }
    65.38462% {
      -webkit-transform: translateX(23vw) translateY(-18vh) scale(0.83);
              transform: translateX(23vw) translateY(-18vh) scale(0.83); }
    69.23077% {
      -webkit-transform: translateX(-16vw) translateY(-44vh) scale(0.57);
              transform: translateX(-16vw) translateY(-44vh) scale(0.57); }
    73.07692% {
      -webkit-transform: translateX(29vw) translateY(29vh) scale(0.94);
              transform: translateX(29vw) translateY(29vh) scale(0.94); }
    76.92308% {
      -webkit-transform: translateX(-16vw) translateY(48vh) scale(0.83);
              transform: translateX(-16vw) translateY(48vh) scale(0.83); }
    80.76923% {
      -webkit-transform: translateX(-44vw) translateY(-19vh) scale(0.64);
              transform: translateX(-44vw) translateY(-19vh) scale(0.64); }
    84.61538% {
      -webkit-transform: translateX(-5vw) translateY(-38vh) scale(0.53);
              transform: translateX(-5vw) translateY(-38vh) scale(0.53); }
    88.46154% {
      -webkit-transform: translateX(46vw) translateY(23vh) scale(0.96);
              transform: translateX(46vw) translateY(23vh) scale(0.96); }
    92.30769% {
      -webkit-transform: translateX(45vw) translateY(-40vh) scale(0.47);
              transform: translateX(45vw) translateY(-40vh) scale(0.47); }
    96.15385% {
      -webkit-transform: translateX(-36vw) translateY(-48vh) scale(0.9);
              transform: translateX(-36vw) translateY(-48vh) scale(0.9); }
    100% {
      -webkit-transform: translateX(41vw) translateY(-12vh) scale(0.48);
              transform: translateX(41vw) translateY(-12vh) scale(0.48); } }
  
  @keyframes move14 {
    0% {
      -webkit-transform: translateX(-48vw) translateY(7vh) scale(0.42);
              transform: translateX(-48vw) translateY(7vh) scale(0.42); }
    3.84615% {
      -webkit-transform: translateX(9vw) translateY(-16vh) scale(0.88);
              transform: translateX(9vw) translateY(-16vh) scale(0.88); }
    7.69231% {
      -webkit-transform: translateX(-31vw) translateY(-1vh) scale(1);
              transform: translateX(-31vw) translateY(-1vh) scale(1); }
    11.53846% {
      -webkit-transform: translateX(-38vw) translateY(-23vh) scale(0.8);
              transform: translateX(-38vw) translateY(-23vh) scale(0.8); }
    15.38462% {
      -webkit-transform: translateX(8vw) translateY(32vh) scale(0.61);
              transform: translateX(8vw) translateY(32vh) scale(0.61); }
    19.23077% {
      -webkit-transform: translateX(-42vw) translateY(-40vh) scale(0.6);
              transform: translateX(-42vw) translateY(-40vh) scale(0.6); }
    23.07692% {
      -webkit-transform: translateX(-31vw) translateY(20vh) scale(0.69);
              transform: translateX(-31vw) translateY(20vh) scale(0.69); }
    26.92308% {
      -webkit-transform: translateX(-40vw) translateY(44vh) scale(0.5);
              transform: translateX(-40vw) translateY(44vh) scale(0.5); }
    30.76923% {
      -webkit-transform: translateX(-40vw) translateY(-9vh) scale(0.37);
              transform: translateX(-40vw) translateY(-9vh) scale(0.37); }
    34.61538% {
      -webkit-transform: translateX(2vw) translateY(-29vh) scale(0.6);
              transform: translateX(2vw) translateY(-29vh) scale(0.6); }
    38.46154% {
      -webkit-transform: translateX(-24vw) translateY(16vh) scale(0.74);
              transform: translateX(-24vw) translateY(16vh) scale(0.74); }
    42.30769% {
      -webkit-transform: translateX(-15vw) translateY(-30vh) scale(0.5);
              transform: translateX(-15vw) translateY(-30vh) scale(0.5); }
    46.15385% {
      -webkit-transform: translateX(2vw) translateY(4vh) scale(0.47);
              transform: translateX(2vw) translateY(4vh) scale(0.47); }
    50% {
      -webkit-transform: translateX(-40vw) translateY(9vh) scale(0.85);
              transform: translateX(-40vw) translateY(9vh) scale(0.85); }
    53.84615% {
      -webkit-transform: translateX(-5vw) translateY(23vh) scale(0.46);
              transform: translateX(-5vw) translateY(23vh) scale(0.46); }
    57.69231% {
      -webkit-transform: translateX(-4vw) translateY(7vh) scale(0.86);
              transform: translateX(-4vw) translateY(7vh) scale(0.86); }
    61.53846% {
      -webkit-transform: translateX(18vw) translateY(-23vh) scale(0.64);
              transform: translateX(18vw) translateY(-23vh) scale(0.64); }
    65.38462% {
      -webkit-transform: translateX(23vw) translateY(-18vh) scale(0.83);
              transform: translateX(23vw) translateY(-18vh) scale(0.83); }
    69.23077% {
      -webkit-transform: translateX(-16vw) translateY(-44vh) scale(0.57);
              transform: translateX(-16vw) translateY(-44vh) scale(0.57); }
    73.07692% {
      -webkit-transform: translateX(29vw) translateY(29vh) scale(0.94);
              transform: translateX(29vw) translateY(29vh) scale(0.94); }
    76.92308% {
      -webkit-transform: translateX(-16vw) translateY(48vh) scale(0.83);
              transform: translateX(-16vw) translateY(48vh) scale(0.83); }
    80.76923% {
      -webkit-transform: translateX(-44vw) translateY(-19vh) scale(0.64);
              transform: translateX(-44vw) translateY(-19vh) scale(0.64); }
    84.61538% {
      -webkit-transform: translateX(-5vw) translateY(-38vh) scale(0.53);
              transform: translateX(-5vw) translateY(-38vh) scale(0.53); }
    88.46154% {
      -webkit-transform: translateX(46vw) translateY(23vh) scale(0.96);
              transform: translateX(46vw) translateY(23vh) scale(0.96); }
    92.30769% {
      -webkit-transform: translateX(45vw) translateY(-40vh) scale(0.47);
              transform: translateX(45vw) translateY(-40vh) scale(0.47); }
    96.15385% {
      -webkit-transform: translateX(-36vw) translateY(-48vh) scale(0.9);
              transform: translateX(-36vw) translateY(-48vh) scale(0.9); }
    100% {
      -webkit-transform: translateX(41vw) translateY(-12vh) scale(0.48);
              transform: translateX(41vw) translateY(-12vh) scale(0.48); } }
  
  .firefly:nth-child(15) {
    -webkit-animation-name: move15;
            animation-name: move15; }
    .firefly:nth-child(15)::before {
      -webkit-animation-duration: 15s;
              animation-duration: 15s; }
    .firefly:nth-child(15)::after {
      -webkit-animation-duration: 15s, 10402ms;
              animation-duration: 15s, 10402ms;
      -webkit-animation-delay: 0ms, 6668ms;
              animation-delay: 0ms, 6668ms; }
  
  @-webkit-keyframes move15 {
    0% {
      -webkit-transform: translateX(44vw) translateY(2vh) scale(0.57);
              transform: translateX(44vw) translateY(2vh) scale(0.57); }
    4.7619% {
      -webkit-transform: translateX(45vw) translateY(-17vh) scale(0.39);
              transform: translateX(45vw) translateY(-17vh) scale(0.39); }
    9.52381% {
      -webkit-transform: translateX(16vw) translateY(-16vh) scale(0.82);
              transform: translateX(16vw) translateY(-16vh) scale(0.82); }
    14.28571% {
      -webkit-transform: translateX(-43vw) translateY(-17vh) scale(0.5);
              transform: translateX(-43vw) translateY(-17vh) scale(0.5); }
    19.04762% {
      -webkit-transform: translateX(-48vw) translateY(37vh) scale(0.52);
              transform: translateX(-48vw) translateY(37vh) scale(0.52); }
    23.80952% {
      -webkit-transform: translateX(-23vw) translateY(36vh) scale(0.42);
              transform: translateX(-23vw) translateY(36vh) scale(0.42); }
    28.57143% {
      -webkit-transform: translateX(-39vw) translateY(29vh) scale(0.66);
              transform: translateX(-39vw) translateY(29vh) scale(0.66); }
    33.33333% {
      -webkit-transform: translateX(-18vw) translateY(-21vh) scale(0.43);
              transform: translateX(-18vw) translateY(-21vh) scale(0.43); }
    38.09524% {
      -webkit-transform: translateX(-49vw) translateY(-10vh) scale(0.91);
              transform: translateX(-49vw) translateY(-10vh) scale(0.91); }
    42.85714% {
      -webkit-transform: translateX(-13vw) translateY(-25vh) scale(0.58);
              transform: translateX(-13vw) translateY(-25vh) scale(0.58); }
    47.61905% {
      -webkit-transform: translateX(44vw) translateY(11vh) scale(0.3);
              transform: translateX(44vw) translateY(11vh) scale(0.3); }
    52.38095% {
      -webkit-transform: translateX(27vw) translateY(-30vh) scale(0.74);
              transform: translateX(27vw) translateY(-30vh) scale(0.74); }
    57.14286% {
      -webkit-transform: translateX(35vw) translateY(35vh) scale(0.82);
              transform: translateX(35vw) translateY(35vh) scale(0.82); }
    61.90476% {
      -webkit-transform: translateX(27vw) translateY(-8vh) scale(0.89);
              transform: translateX(27vw) translateY(-8vh) scale(0.89); }
    66.66667% {
      -webkit-transform: translateX(-10vw) translateY(-37vh) scale(0.35);
              transform: translateX(-10vw) translateY(-37vh) scale(0.35); }
    71.42857% {
      -webkit-transform: translateX(-35vw) translateY(37vh) scale(0.34);
              transform: translateX(-35vw) translateY(37vh) scale(0.34); }
    76.19048% {
      -webkit-transform: translateX(-29vw) translateY(34vh) scale(0.33);
              transform: translateX(-29vw) translateY(34vh) scale(0.33); }
    80.95238% {
      -webkit-transform: translateX(4vw) translateY(-4vh) scale(0.7);
              transform: translateX(4vw) translateY(-4vh) scale(0.7); }
    85.71429% {
      -webkit-transform: translateX(43vw) translateY(0vh) scale(1);
              transform: translateX(43vw) translateY(0vh) scale(1); }
    90.47619% {
      -webkit-transform: translateX(-9vw) translateY(-44vh) scale(0.85);
              transform: translateX(-9vw) translateY(-44vh) scale(0.85); }
    95.2381% {
      -webkit-transform: translateX(23vw) translateY(33vh) scale(0.31);
              transform: translateX(23vw) translateY(33vh) scale(0.31); }
    100% {
      -webkit-transform: translateX(-36vw) translateY(10vh) scale(0.82);
              transform: translateX(-36vw) translateY(10vh) scale(0.82); } }
  
  @keyframes move15 {
    0% {
      -webkit-transform: translateX(44vw) translateY(2vh) scale(0.57);
              transform: translateX(44vw) translateY(2vh) scale(0.57); }
    4.7619% {
      -webkit-transform: translateX(45vw) translateY(-17vh) scale(0.39);
              transform: translateX(45vw) translateY(-17vh) scale(0.39); }
    9.52381% {
      -webkit-transform: translateX(16vw) translateY(-16vh) scale(0.82);
              transform: translateX(16vw) translateY(-16vh) scale(0.82); }
    14.28571% {
      -webkit-transform: translateX(-43vw) translateY(-17vh) scale(0.5);
              transform: translateX(-43vw) translateY(-17vh) scale(0.5); }
    19.04762% {
      -webkit-transform: translateX(-48vw) translateY(37vh) scale(0.52);
              transform: translateX(-48vw) translateY(37vh) scale(0.52); }
    23.80952% {
      -webkit-transform: translateX(-23vw) translateY(36vh) scale(0.42);
              transform: translateX(-23vw) translateY(36vh) scale(0.42); }
    28.57143% {
      -webkit-transform: translateX(-39vw) translateY(29vh) scale(0.66);
              transform: translateX(-39vw) translateY(29vh) scale(0.66); }
    33.33333% {
      -webkit-transform: translateX(-18vw) translateY(-21vh) scale(0.43);
              transform: translateX(-18vw) translateY(-21vh) scale(0.43); }
    38.09524% {
      -webkit-transform: translateX(-49vw) translateY(-10vh) scale(0.91);
              transform: translateX(-49vw) translateY(-10vh) scale(0.91); }
    42.85714% {
      -webkit-transform: translateX(-13vw) translateY(-25vh) scale(0.58);
              transform: translateX(-13vw) translateY(-25vh) scale(0.58); }
    47.61905% {
      -webkit-transform: translateX(44vw) translateY(11vh) scale(0.3);
              transform: translateX(44vw) translateY(11vh) scale(0.3); }
    52.38095% {
      -webkit-transform: translateX(27vw) translateY(-30vh) scale(0.74);
              transform: translateX(27vw) translateY(-30vh) scale(0.74); }
    57.14286% {
      -webkit-transform: translateX(35vw) translateY(35vh) scale(0.82);
              transform: translateX(35vw) translateY(35vh) scale(0.82); }
    61.90476% {
      -webkit-transform: translateX(27vw) translateY(-8vh) scale(0.89);
              transform: translateX(27vw) translateY(-8vh) scale(0.89); }
    66.66667% {
      -webkit-transform: translateX(-10vw) translateY(-37vh) scale(0.35);
              transform: translateX(-10vw) translateY(-37vh) scale(0.35); }
    71.42857% {
      -webkit-transform: translateX(-35vw) translateY(37vh) scale(0.34);
              transform: translateX(-35vw) translateY(37vh) scale(0.34); }
    76.19048% {
      -webkit-transform: translateX(-29vw) translateY(34vh) scale(0.33);
              transform: translateX(-29vw) translateY(34vh) scale(0.33); }
    80.95238% {
      -webkit-transform: translateX(4vw) translateY(-4vh) scale(0.7);
              transform: translateX(4vw) translateY(-4vh) scale(0.7); }
    85.71429% {
      -webkit-transform: translateX(43vw) translateY(0vh) scale(1);
              transform: translateX(43vw) translateY(0vh) scale(1); }
    90.47619% {
      -webkit-transform: translateX(-9vw) translateY(-44vh) scale(0.85);
              transform: translateX(-9vw) translateY(-44vh) scale(0.85); }
    95.2381% {
      -webkit-transform: translateX(23vw) translateY(33vh) scale(0.31);
              transform: translateX(23vw) translateY(33vh) scale(0.31); }
    100% {
      -webkit-transform: translateX(-36vw) translateY(10vh) scale(0.82);
              transform: translateX(-36vw) translateY(10vh) scale(0.82); } }
  
  @-webkit-keyframes drift {
    0% {
      -webkit-transform: rotate(0deg);
              transform: rotate(0deg); }
    100% {
      -webkit-transform: rotate(360deg);
              transform: rotate(360deg); } }
  
  @keyframes drift {
    0% {
      -webkit-transform: rotate(0deg);
              transform: rotate(0deg); }
    100% {
      -webkit-transform: rotate(360deg);
              transform: rotate(360deg); } }
  
  @-webkit-keyframes flash {
    0%, 30%, 100% {
      opacity: 0;
      box-shadow: 0 0 0vw 0vw white; }
    5% {
      opacity: 1;
      box-shadow: 0 0 2vw 0.4vw white; } }
  
  @keyframes flash {
    0%, 30%, 100% {
      opacity: 0;
      box-shadow: 0 0 0vw 0vw white; }
    5% {
      opacity: 1;
      box-shadow: 0 0 2vw 0.4vw white; } }
  
.app-cursor {
    z-index: 10000;
    border-radius: 50%;
    width: 9px;
    height: 9px;
    overflow: hidden;
    position: fixed;
    background-color: #CCD6F6;
    pointer-events: none;
    mix-blend-mode: difference;
    display: flex;
    align-items: center;
    justify-content: center;
}

.app-cursor>p {
    font-size: 1.4rem;
    text-align: center;
    font-weight: 600;
    display: none;
}

.follower {
    z-index: 10000;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    pointer-events: none;
    overflow: hidden;
    -webkit-transform: translate(0, 0, 0);
            transform: translate(0, 0, 0);
    position: fixed;
    border: 2px solid #CCD6F6;
    pointer-events: none;
    opacity: .5;
    display: flex;
    align-items: center;
    justify-content: center;
}

.follower::before {
    content: "";
    width: 80%;
    height: 80%;
    position: absolute;
    border: 6px solid #CCD6F6;
    border-radius: 50%;
    opacity: .5;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    -webkit-animation: cursorAnim2 .8s infinite;
            animation: cursorAnim2 .8s infinite;
}

@-webkit-keyframes cursorAnim2 {
    0% {
        width: 100%;
        height: 100%;
    }
    50% {
        width: 80%;
        height: 80%;
    }
    100% {
        width: 100%;
        height: 100%;
    }
}

@keyframes cursorAnim2 {
    0% {
        width: 100%;
        height: 100%;
    }
    50% {
        width: 80%;
        height: 80%;
    }
    100% {
        width: 100%;
        height: 100%;
    }
}

@media screen and (max-width:800px) {
    .app-cursor {
        visibility: hidden;
        display: none;
    }
    .follower {
        visibility: hidden;
        display: none;
    }
}
