.app-cursor {
    z-index: 10000;
    border-radius: 50%;
    width: 9px;
    height: 9px;
    overflow: hidden;
    position: fixed;
    background-color: #CCD6F6;
    pointer-events: none;
    mix-blend-mode: difference;
    display: flex;
    align-items: center;
    justify-content: center;
}

.app-cursor>p {
    font-size: 1.4rem;
    text-align: center;
    font-weight: 600;
    display: none;
}

.follower {
    z-index: 10000;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    pointer-events: none;
    overflow: hidden;
    transform: translate(0, 0, 0);
    position: fixed;
    border: 2px solid #CCD6F6;
    pointer-events: none;
    opacity: .5;
    display: flex;
    align-items: center;
    justify-content: center;
}

.follower::before {
    content: "";
    width: 80%;
    height: 80%;
    position: absolute;
    border: 6px solid #CCD6F6;
    border-radius: 50%;
    opacity: .5;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    animation: cursorAnim2 .8s infinite;
}

@keyframes cursorAnim2 {
    0% {
        width: 100%;
        height: 100%;
    }
    50% {
        width: 80%;
        height: 80%;
    }
    100% {
        width: 100%;
        height: 100%;
    }
}

@media screen and (max-width:800px) {
    .app-cursor {
        visibility: hidden;
        display: none;
    }
    .follower {
        visibility: hidden;
        display: none;
    }
}